import gql from 'graphql-tag';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import { VideoContentBlockFragment } from '../../entities/operationResults';
import Container from '../atoms/Container';
import ReactPlayer from 'react-player';

export const fragment = gql`
    fragment VideoContentBlock on contentBlocks_video_BlockType {
        videoUrl
    }
`;

interface Props {
    contentBlock: VideoContentBlockFragment;
}

const VideoContentBlock: SFC<Props> = ({ contentBlock: { videoUrl } }) => (
    <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'8rem'}>
        <Wrapper>
            <ReactPlayer
                width="100%"
                height="100%"
                className="react-player"
                url={videoUrl || ''}
                config={{
                    youtube: {
                        playerVars: { modestbranding: 1 }
                    }
                }}
            />
        </Wrapper>
    </Container>
);

const Wrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export default VideoContentBlock;