import * as React from 'react';
import {Fallback, Img} from 'react-image-loading';

type Props = React.ImgHTMLAttributes<HTMLImageElement>;

import { useInView } from 'react-intersection-observer';
import {SFC} from "react";

const LazyImg: SFC<Props> = (props) => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Load only once
        threshold: 0.1,    // Load when 10% of the image is visible
    });

    return (
        <div ref={ref}>
            {inView ? <Img {...props} /> : <Fallback />}
        </div>
    );
};

export default LazyImg;