import React, { SFC } from 'react';
import {
    DocumentationCollectionItemFragment,
    EventCollectionItemFragment,
    InfoCollectionItemFragment,
    NewsCollectionItemFragment,
    NewsletterCollectionItemFragment,
    ServiceCollectionItemFragment,
    StoryCollectionItemFragment
} from '../../../entities/operationResults';
import styled from '../../../styled-components';
import CollectionItem from './CollectionItem';

interface Props {
    collectionItems: Array<NewsCollectionItemFragment | StoryCollectionItemFragment | EventCollectionItemFragment | InfoCollectionItemFragment | DocumentationCollectionItemFragment | ServiceCollectionItemFragment | NewsletterCollectionItemFragment>;
    featured?: Array<NewsCollectionItemFragment | StoryCollectionItemFragment | EventCollectionItemFragment | InfoCollectionItemFragment | DocumentationCollectionItemFragment | ServiceCollectionItemFragment> | null;
}

function getFeaturedId(featured: Array<NewsCollectionItemFragment | StoryCollectionItemFragment | EventCollectionItemFragment | InfoCollectionItemFragment | DocumentationCollectionItemFragment | ServiceCollectionItemFragment> | null) {
    const array: number[] = [];
    if (featured !== null) {
        featured.forEach((featuredItem: NewsCollectionItemFragment | StoryCollectionItemFragment | EventCollectionItemFragment | InfoCollectionItemFragment | DocumentationCollectionItemFragment | ServiceCollectionItemFragment) => {
            const id = featuredItem.id !== null ? Number(featuredItem.id) : 0;
            array.push(id);
        });
    }
    return array;
}

const CollectionList: SFC<Props> = ({ collectionItems, featured }) => {
    const featuredItems = getFeaturedId(featured ? featured : null);
    return (
        <List>
            {collectionItems.map((collectionItem: NewsCollectionItemFragment | StoryCollectionItemFragment | EventCollectionItemFragment | InfoCollectionItemFragment | DocumentationCollectionItemFragment | ServiceCollectionItemFragment) => {
                const id = collectionItem.id !== null ? Number(collectionItem.id) : 0;
                return collectionItem.uri && featuredItems.indexOf(id) === -1 && (
                    <CollectionItem key={id} collectionItem={collectionItem} />
                );
            })}
        </List>
    );
};

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export default CollectionList;
