import gql from 'graphql-tag';
import styled from '../styled-components';
import Container from './atoms/Container';
import { ExchangeParams } from './Exchange';
import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import { DataProps, graphql } from 'react-apollo';
import {
    DocumentationFragment, DocumentationQuery as Response,
} from '../entities/operationResults';
import { branch, compose, renderComponent } from 'recompose';
import NotFound from './NotFound';
import { ThemeProps, withTheme } from '../styled-components';
import { default as ContentBlocks, contentBlockFragments } from './organisms/ContentBlocks';
import Meta from './atoms/Meta';
import PageNav, { MenuItem } from './organisms/PageNav';
import { default as RelatedDetail, relatedFragments } from './organisms/RelatedDetail';
import withSpinner from '../hocs/withSpinner';
import theme from '../constants/theme';

const GET_DOCUMENTATION_QUERY = gql`
    query Documentation ($exchange: [String!], $slug: [String!]) {
        entry(section: "documentation", site: $exchange, slug: $slug) {
            ...Documentation
        }
    }

    fragment Documentation on documentation_documentation_Entry {
        title
        description
        contentBlocks {
            ...ContentBlock
        }
        relatedItems {
            ...Related
        }
    }

    ${relatedFragments}
    ${contentBlockFragments}
`;

interface DocumentationParams extends ExchangeParams {
    slug: string;
}

type Props = RouteComponentProps<DocumentationParams> & DataProps<Response> & ThemeProps;

const getMenuStructure = (contentBlocks: DocumentationFragment['contentBlocks']) => {
    const menuStructure: MenuItem[] = [];

    if (contentBlocks) {
        let currentIntro: MenuItem | null = null;
        contentBlocks.forEach((block) => {
            if (block && block.__typename === 'contentBlocks_intro_BlockType') {
                if (block.id && block.heading) {
                    currentIntro = {
                        id: `section-${block.id}`,
                        title: block.heading,
                        children: []
                    };
                    menuStructure.push(currentIntro);
                }
            } else if (block && block.__typename === 'contentBlocks_text_BlockType') {
                if (block.id && block.heading && currentIntro && currentIntro.children) {
                    currentIntro.children.push({
                        id: `section-${block.id}`,
                        title: block.heading
                    });
                }
            }
        });
    }

    return menuStructure;
};

const Documentation: SFC<Props> = ({ data: { entry } }) => {
    const documentation: DocumentationFragment | null = getEntryOfType('documentation_documentation_Entry', entry);
    if (!documentation) { return null; }
    const relatedItems = getRelatedItems(documentation.relatedItems);
    const menuStructure = getMenuStructure(documentation.contentBlocks);

    return documentation && (
        <Fragment>
            <Content>
                <Meta title={documentation.title || ''} description={documentation.description} />
                <MobileContainer maxWidth paddingTopL={'6rem'} paddingTop={'2rem'}>
                    <MobileTitle>{documentation.title}</MobileTitle>
                </MobileContainer>
                <PageNavContainer>
                    <StyledPageNav menu={menuStructure} />
                </PageNavContainer>
                <ContentBlocks contentBlocks={documentation.contentBlocks} narrow />
            </Content>
            {documentation.relatedItems && documentation.relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
        </Fragment>
    );
};

const Content = styled.div`
    position: relative;
`;

const MobileContainer = styled(Container)`
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        display: none;
    }
`;

const MobileTitle = styled.h2`
    font: ${props => props.theme.fonts.small.introTitle};
`;

const PageNavContainer = styled.div`
    position: absolute;
    right: 0;
    width: 32rem;
    bottom: 0;
    top: 0;
    @media screen and (max-width: ${props => props.theme.mediaQueries.l}) {
        position: static;
        width: auto;
    }
`;

const StyledPageNav = styled(PageNav)`
    position: sticky;
    right: 2rem;
    top: 10rem;
    float: right;
    width: 32rem;
    
    @media screen and (max-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 960px;
        position: relative;
        right: 0;
        top: 0;
        margin: 0 auto;
        float: none;
        padding: 0 8rem;
        width: auto;
    }
    
    @media screen and (max-width: ${theme.mediaQueries.m}) {
        max-width: 636px;
        padding: 0 2.4rem;
    }
`;

const enhance = compose(
    graphql(GET_DOCUMENTATION_QUERY, {
        options: ({ match: { params: { slug, exchange } } }: Props) => ({
            variables: {
                slug,
                exchange
            }
        })
    }),
    withSpinner,
    branch(
        ({ data }: Props) => !data.entry,
        renderComponent(NotFound)
    ),
    withTheme
);

export default enhance(Documentation);