import gql from 'graphql-tag';
import { ExchangeParams } from './Exchange';
import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import {getEntriesOfType, getEntryOfType, getRelatedItems} from '../services/entryTypes';
import { DataProps, graphql } from 'react-apollo';
import {
    GetStartedPageFragment,
    GetStartedQuery as Response, GetStartedFragment
} from '../entities/operationResults';
import { compose } from 'recompose';
import { default as ContentBlocks, contentBlockFragments } from './organisms/ContentBlocks';
import Meta from './atoms/Meta';
import { default as RelatedDetail, relatedFragments } from './organisms/RelatedDetail';
import withSpinner from '../hocs/withSpinner';

const GET_GETSTARTED_QUERY = gql`
    query GetStarted ($exchange: [String!]) {
        entry(section: "getStarted", site: $exchange) {
            ...GetStarted
        }
    }

    fragment GetStarted on getStarted_getStarted_Entry {
        linkEntry {
            ...GetStartedPage
        }
    }
    
    fragment GetStartedPage on documentation_documentation_Entry {
        title
        description
        contentBlocks {
            ...ContentBlock
        }
        relatedItems {
            ...Related
        }
    }

    ${relatedFragments}
    ${contentBlockFragments}
`;

interface GetStartedParams extends ExchangeParams {
    slug: string;
}

type Props = RouteComponentProps<GetStartedParams> & DataProps<Response>;

const GetStarted: SFC<Props> = ({ data: { entry } }) => {
    const getStarted: GetStartedFragment | null = getEntryOfType('getStarted_getStarted_Entry', entry);
    if (!getStarted) { return null; }
    // @ts-ignore
    const getStartedPage: GetStartedPageFragment[] | null = getEntriesOfType(
        'documentation_documentation_Entry',
        getStarted && getStarted.linkEntry
    );
    if (!getStartedPage) { return null; }

    const relatedItems = getRelatedItems(getStartedPage && getStartedPage[0].relatedItems);

    return (
        <Fragment>
            <Meta title={getStartedPage[0].title || ''} description={getStartedPage[0].description} />
            <ContentBlocks contentBlocks={getStartedPage && getStartedPage[0].contentBlocks} />
            {getStarted && getStartedPage[0].relatedItems && relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
        </Fragment>
    );
};

const enhance = compose(
    graphql(GET_GETSTARTED_QUERY, {
        options: ({ match: { params: { exchange } } }: Props) => ({
            variables: { exchange }
        })
    }),
    withSpinner
);

export default enhance(GetStarted);