import React, { SFC } from 'react';
import Container from '../atoms/Container';
import {
    RelatedEventFragment,
    RelatedInfoFragment,
    RelatedServiceFragment,
    RelatedStoryFragment,
    RelatedNewsFragment,
    RelatedDocumentationFragment
} from '../../entities/operationResults';
import theme from '../../constants/theme';
import styled from '../../styled-components';
import StoryPreview from '../molecules/StoryPreview';
import gql from 'graphql-tag';
import ContentTitle from '../atoms/ContentTitle';

import { format } from 'date-fns';

const formatDate = (dateString: string, formatString = "DD MMMM YYYY", timezone = "Europe/Amsterdam") => {
    const date = new Date(dateString);
    // You can use a library like 'date-fns-tz' for timezone handling if needed
    return format(date, formatString);
};

const storyFragment = gql`
    fragment RelatedStory on story_story_Entry {
        id
        uri
        title
        postDate
        heroImage {
            srcSmall: url(transform: "storyHeroSmall")
            widthSmall: width(transform: "storyHeroSmall")
            srcMedium: url(transform: "storyHeroMedium")
            widthMedium: width(transform: "storyHeroMedium")
            srcLarge: url(transform: "storyHeroLarge")
            widthLarge: width(transform: "storyHeroLarge")
            title
        }
        service {
            title
        }
       
    }
`;

const eventFragment = gql`
    fragment RelatedEvent on event_event_Entry {
        id
        uri
        title
        postDate
        heroImage {
            srcSmall: url(transform: "storyHeroSmall")
            widthSmall: width(transform: "storyHeroSmall")
            srcMedium: url(transform: "storyHeroMedium")
            widthMedium: width(transform: "storyHeroMedium")
            srcLarge: url(transform: "storyHeroLarge")
            widthLarge: width(transform: "storyHeroLarge")
            title
        }
    }
`;

const serviceFragment = gql`
    fragment RelatedService on service_service_Entry {
        id
        uri
        title
        postDate
        relatedItemImage {
            srcSmall: url(transform: "storyHeroSmall")
            widthSmall: width(transform: "storyHeroSmall")
            srcMedium: url(transform: "storyHeroMedium")
            widthMedium: width(transform: "storyHeroMedium")
            srcLarge: url(transform: "storyHeroLarge")
            widthLarge: width(transform: "storyHeroLarge")
            title
        }
    }
`;

const infoFragment = gql`
    fragment RelatedInfo on info_info_Entry {
        id
        uri
        title
        postDate
        relatedItemImage {
            srcSmall: url(transform: "storyHeroSmall")
            widthSmall: width(transform: "storyHeroSmall")
            srcMedium: url(transform: "storyHeroMedium")
            widthMedium: width(transform: "storyHeroMedium")
            srcLarge: url(transform: "storyHeroLarge")
            widthLarge: width(transform: "storyHeroLarge")
            title
        }
    }
`;

const documentationFragment = gql`
    fragment RelatedDocumentation on documentation_documentation_Entry {
        id
        uri
        title
        postDate
        relatedItemImage {
            srcSmall: url(transform: "storyHeroSmall")
            widthSmall: width(transform: "storyHeroSmall")
            srcMedium: url(transform: "storyHeroMedium")
            widthMedium: width(transform: "storyHeroMedium")
            srcLarge: url(transform: "storyHeroLarge")
            widthLarge: width(transform: "storyHeroLarge")
            title
        }
    }
`;

const newsFragment = gql`
    fragment RelatedNews on news_news_Entry {
        id
        uri
        title
        postDate
        heroImage {
            srcSmall: url(transform: "storyHeroSmall")
            widthSmall: width(transform: "storyHeroSmall")
            srcMedium: url(transform: "storyHeroMedium")
            widthMedium: width(transform: "storyHeroMedium")
            srcLarge: url(transform: "storyHeroLarge")
            widthLarge: width(transform: "storyHeroLarge")
            title
        }
    }
`;

export const relatedFragments = gql`
    fragment Related on EntryInterface {
        ...RelatedStory
        ...RelatedEvent
        ...RelatedService
        ...RelatedInfo
        ...RelatedNews
        ...RelatedDocumentation
    }
    
    ${infoFragment}
    ${storyFragment}
    ${eventFragment}
    ${serviceFragment}
    ${newsFragment}
    ${documentationFragment}
`;

interface Props {
    relatedItems?: Array<RelatedStoryFragment | RelatedEventFragment | RelatedServiceFragment | RelatedInfoFragment | RelatedNewsFragment | RelatedDocumentationFragment>;
}

const RelatedDetail: SFC<Props> = ({ relatedItems }) => {
    return (
        <section>
            <Container backgroundColor={theme.colors.aquaHaze} paddingTop={'5rem'} paddingTopL={'8rem'}>
                <TitleWrapper>
                    <ContentTitle>You might also be interested in</ContentTitle>
                </TitleWrapper>

                <Stories>
                {relatedItems && relatedItems.map((item) => {
                    if (!item) { return null; }
                    const { id, title } = item;
                    let { postDate } = item;
                    const uri = '/' + item.uri;
                    let firstImage = null;
                    let serviceTitle = '';

                    postDate = postDate && formatDate(postDate, "DD MMMM YYYY", "Europe/Amsterdam");

                    switch (item.__typename) {
                        case 'story_story_Entry': {
                            postDate = '';
                            const heroImage = item && item.heroImage && item.heroImage[0];
                            if (heroImage) {
                                firstImage = heroImage;
                            }
                            const service = item && item.service && item.service[0];
                            if (service) {
                                serviceTitle = service.title || '';
                            }
                            break;
                        }
                        case 'event_event_Entry': {
                            const heroImage = item && item.heroImage && item.heroImage[0];
                            if (heroImage) {
                                firstImage = heroImage;
                            }
                            break;
                        }
                        case 'service_service_Entry': {
                            postDate = 'service';
                            const relatedItemImage = item && item.relatedItemImage && item.relatedItemImage[0];
                            if (relatedItemImage) {
                                firstImage = relatedItemImage;
                            }
                            break;
                        }
                        case 'info_info_Entry': {
                            postDate = 'more info';
                            const relatedItemImage = item && item.relatedItemImage && item.relatedItemImage[0];
                            if (relatedItemImage) {
                                firstImage = relatedItemImage;
                            }
                            break;
                        }
                        case 'documentation_documentation_Entry': {
                            postDate = 'documentation';
                            const relatedItemImage = item && item.relatedItemImage && item.relatedItemImage[0];
                            if (relatedItemImage) {
                                firstImage = relatedItemImage;
                            }
                            break;
                        }
                        case 'news_news_Entry': {
                            const heroImage = item && item.heroImage && item.heroImage[0];
                            if (heroImage) {
                                firstImage = heroImage;
                            }
                            break;
                        }
                        default: {
                            firstImage = null;
                        }
                    }
                    return uri && (
                        <StoryPreview
                            key={id || ''}
                            link={uri}
                            service={serviceTitle}
                            title={title || ''}
                            img={firstImage ? {
                                title: firstImage.title,
                                srcSmall: firstImage.srcSmall,
                                widthSmall: firstImage.widthSmall ? firstImage.widthSmall.toString() : null,
                                srcMedium: firstImage.srcMedium,
                                widthMedium: firstImage.widthMedium ? firstImage.widthMedium.toString() : null,
                                srcLarge: firstImage.srcLarge,
                                widthLarge: firstImage.widthLarge ? firstImage.widthLarge.toString() : null
                            } : undefined}
                            date={postDate}
                        />
                    );
                })}
                </Stories>
            </Container>
        </section>
    );
};

const TitleWrapper = styled.div`
    padding-left: 3rem;
`;

const Stories = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    padding: 0.5rem;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: row;
    }
`;

export default RelatedDetail;