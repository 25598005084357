import React, { FC, useEffect, useState } from 'react';
import { DataValue } from 'react-apollo';
import styled  from '../../styled-components';
import IconButton from '../atoms/buttons/IconButton';
import CloseIcon from '../atoms/icons/CloseIcon';
import DefaultButton from '../atoms/buttons/DefaultButton';
import { MenuQuery } from '../../entities/operationResults';

interface Props {
    data: DataValue<MenuQuery> | null;
}

const Modal: FC<Props> = ({
    data
}) => {
    if (!data || !data.globalSet) {
        return null;
    }
    const [showModal, setShowModal] = useState(false);
    const title: string | null = data.globalSet.__typename === 'modalSettings_GlobalSet' ? data.globalSet.modalTitle : 'Platform Update';
    const text: string | null = data.globalSet.__typename === 'modalSettings_GlobalSet' ? data.globalSet.modalText : '';
    let entryLink: string = '/';

    if (data.globalSet.__typename === 'modalSettings_GlobalSet' && data.globalSet!.modalEntry && data.globalSet.modalEntry!.length > 0) {
        // @ts-ignore
        entryLink = data.globalSet.modalSettings!.modalEntry[0]!.uri || '/';
    }
    
    useEffect(() => {
        // @ts-ignore
        const show: any = data.globalSet!.modalSettings ? data.globalSet!.modalSettings.showModal : false;

        // Show modal if data received from props is true
        if (show) {
            setShowModal(true);
        }
    }, []);

    return (
        <ModalBase show={showModal}>
            <ModalContent>
                <Top>
                    <Title>
                        <h3>
                            <strong>{title}</strong>
                        </h3>
                    </Title>
                    <CloseButton
                        onClick={() => setShowModal(false)}
                        icon={<CloseIcon />} 
                        colorScheme="dark">
                        Close
                    </CloseButton>
                </Top>
                <Text>
                    <p>
                        {text}
                    </p>
                </Text>
                <DefaultButton to={entryLink ? entryLink : '/'} colorScheme={'colored'} onClick={() => setShowModal(false)}>
                    MORE INFORMATION
                </DefaultButton>
            </ModalContent>
        </ModalBase>
    )
}

const ModalBase = styled.div<{ show: boolean }>`
    display: ${props => props.show === true ? 'flex' : 'none'};
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: ${(props) => props.theme.colors.black};
    background-color: rgba(0,0,0,0.6);
`;

const Text = styled.div`
    margin-bottom: 3rem;
    font: ${props => props.theme.fonts.small.introText};
    letter-spacing: 0.3px;
    margin-top: 3.2rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.introText};
    }

    p {
        margin-top: 0;
        margin-bottom: 3.2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(100vw - 6.6rem);
    
    h3 {
        margin:0;
        font: ${(props) => props.theme.fonts.small.sectionHeaderTitle}
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        max-width: ${(props) => props.theme.maxWidths.s}px;
        h3 {
            font: ${(props) => props.theme.fonts.medium.sectionHeaderTitle}
        }
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        max-width: ${(props) => props.theme.maxWidths.m}px;
        h3 {
            font: ${(props) => props.theme.fonts.large.sectionHeaderTitle}
        }
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: ${(props) => props.theme.maxWidths.l}px;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
        max-width: ${(props) => props.theme.maxWidths.xl}px;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xxl}) {
        max-width: ${(props) => props.theme.maxWidths.xxl}px;
    }   
`;

const CloseButton = styled(IconButton)`
    font: ${props => props.theme.fonts.large.textLink};
    text-transform: uppercase;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ModalContent = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    margin: auto;
    width: 70%;
    padding: 3rem 2.5rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding: 4rem 3.5rem;
        width: 45%;
    }
`;

export default Modal;