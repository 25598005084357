import gql from 'graphql-tag';
import { path } from 'ramda';
import React, { SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import {
    DropdownStatsFragment,
    DropDownStatsQuery,
    MenuColumnFragment,
    MenuFragment,
    MenuItemFragment,
    MenuScheme,
} from '../../../../entities/operationResults';
import { getEntriesOfType } from '../../../../services/entryTypes';
import { formatBitUnits } from '../../../../services/numberFormatting';
import styled from '../../../../styled-components';
import Container from '../../../atoms/Container';
import LinkIconRight from '../../../atoms/icons/LinkIconRight';
import { NavColumn, NavHeading, NavItem, NavLink, NavList } from '../../../atoms/NavItems';
import { ExchangeParams } from '../../../Exchange';
import ChartGraph from '../../ChartGraph';

interface OutProps extends DataProps<DropDownStatsQuery>, RouteComponentProps<ExchangeParams> {
    menuItemLists: MenuItemFragment[];
}

interface InProps {
    menuItems: MenuFragment;
    setVisible: (visible: boolean) => void;
    scheme: MenuScheme;
}

export const GET_STATISTICS = gql`
    query DropDownStats($site: [String!]) {
        dropdownStats: entries(site: $site, section: "graph", interval: "daily", target: "totalall") {
            ...DropdownStats
        }
        vitals: globalSet(site: $site, handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
            }
        }
    }

    fragment DropdownStats on graph_graph_Entry {
        title
        interval
        peakIn
        peakOut
        averageIn
        averageOut
        currentIn
        currentOut
        averageInputData {
            time
            value
        }
    }
`;

const MemoizedChartGraph = React.memo(ChartGraph);

const DropdownStats: SFC<OutProps & InProps> = ({ data, menuItems, setVisible, scheme }) => {
    const stats: DropdownStatsFragment[] | null = getEntriesOfType('graph_graph_Entry', data.dropdownStats);
    const dailyStats = stats && stats[0];
    const menuColumns: MenuColumnFragment[] | null = getEntriesOfType('menu_menu_Entry', menuItems.children);
    const menuColumn: MenuColumnFragment = menuColumns && menuColumns[0];
    const menuItemChildren: MenuItemFragment[] | null = getEntriesOfType('menu_menu_Entry', menuColumn.children);
    const peakTraffic = path<number>(['vitals', 'peakTraffic'], data);

    if (!dailyStats) {
        return null;
    }

    const peakIn = dailyStats.peakIn !== null ? formatBitUnits(Number(dailyStats.peakIn)) : null;
    const peakOut = dailyStats.peakOut !== null ? formatBitUnits(Number(dailyStats.peakOut)) : null;
    const averageIn = dailyStats.averageIn !== null ? formatBitUnits(Number(dailyStats.averageIn)) : null;
    const averageOut = dailyStats.averageOut !== null ? formatBitUnits(Number(dailyStats.averageOut)) : null;
    const currentIn = dailyStats.currentIn !== null ? formatBitUnits(Number(dailyStats.currentIn)) : null;
    const currentOut = dailyStats.currentOut !== null ? formatBitUnits(Number(dailyStats.currentOut)) : null;
    const averageInData = dailyStats.averageInputData !== null ? dailyStats.averageInputData.map(item => {
        return item !== null ? {
            ...item,
            time: item.time !== null ? Number(item.time) : null,
            value: item.value !== null ? Number(item.value) : null,
        } : null;
    }) : [];

    return (
        <Container maxWidth={true} paddingTop={'5.6rem'} paddingBottom={'6.4rem'}>
            <div style={{ display: 'flex' }}>
                <Graph>
                    <Heading scheme={scheme} paddingLeft={'5.4rem'}>
                        {dailyStats.title}
                    </Heading>
                    <MemoizedChartGraph
                        scheme={scheme}
                        averageIn={averageInData}
                        peakLine={peakTraffic || 0}
                        interval={'daily'}
                    />
                </Graph>
                <StatsContainer>
                    <Stats>
                        <Stat>
                            <StatTitle>Peak in</StatTitle>
                            <StatAmount>
                                {peakIn && peakIn.amount} <span>{peakIn && peakIn.unit}/s</span>
                            </StatAmount>
                        </Stat>
                        <Stat>
                            <StatTitle>Peak out</StatTitle>
                            <StatAmount>
                                {peakOut && peakOut.amount} <span>{peakOut && peakOut.unit}/s</span>
                            </StatAmount>
                        </Stat>
                        <Stat>
                            <StatTitle>Average in</StatTitle>
                            <StatAmount>
                                {averageIn && averageIn.amount} <span>{averageIn && averageIn.unit}/s</span>
                            </StatAmount>
                        </Stat>
                        <Stat>
                            <StatTitle>Average out</StatTitle>
                            <StatAmount>
                                {averageOut && averageOut.amount} <span>{averageOut && averageOut.unit}/s</span>
                            </StatAmount>
                        </Stat>
                        <Stat>
                            <StatTitle>Current in</StatTitle>
                            <StatAmount>
                                {currentIn && currentIn.amount} <span>{currentIn && currentIn.unit}/s</span>
                            </StatAmount>
                        </Stat>
                        <Stat>
                            <StatTitle>Current out</StatTitle>
                            <StatAmount>
                                {currentOut && currentOut.amount} <span>{currentOut && currentOut.unit}/s</span>
                            </StatAmount>
                        </Stat>
                    </Stats>
                </StatsContainer>
                <NavColumn>
                    <NavHeading>Statistics</NavHeading>
                    <NavList column>
                        {menuItemChildren.map(({ id, title, linkEntry }) => {
                            const linkObj = linkEntry && linkEntry[0];
                            return linkObj && linkObj.uri ? (
                                <NavItem key={id || ''}>
                                    <NavLink
                                        to={`/${linkObj.uri}`}
                                        data-active={false}
                                        onClick={() => {
                                            setVisible(false);
                                        }}
                                    >
                                        {title}
                                        <LinkIconRight />
                                    </NavLink>
                                </NavItem>
                            ) : null;
                        })}
                    </NavList>
                </NavColumn>
            </div>
        </Container>
    );
};

const Graph = styled.div`
    display: none;
    width: calc(100% - 64.6rem);
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        display: block;
    }
`;

const StatsContainer = styled.div`
    box-sizing: border-box;
    display: none;
    position: relative;
    margin-right: 2.4rem;

    &:after {
        content: '';
        position: absolute;
        display: block;
        background: ${props => props.theme.colors.transparentGrey};
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        display: block;
        width: 33%;
        padding-right: 5rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        width: 48rem;
        padding: 4rem 4rem 0 2.4rem;
    }
`;

const Stats = styled.ul`
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Stat = styled.li`
    box-sizing: border-box;
    list-style: none;
    width: 50%;
    text-align: center;

    margin-bottom: 4.8rem;
`;

const StatTitle = styled.div`
    font: ${props => props.theme.fonts.large.smallCaps};
    text-transform: uppercase;
    color: ${props => props.theme.colors.shuttleGray};

    margin-bottom: 1.2rem;
`;

const StatAmount = styled.div`
    font-family: chalet-new-york-sixty;
    font-size: 2.4rem;
    line-height: 1;

    > span {
        font-size: 1.4rem;
    }
`;

const Heading = styled.h2<{ paddingLeft?: string; scheme: MenuScheme }>`
    opacity: 0.5;
    font: ${props => props.theme.fonts.large.smallCaps};
    color: ${props => (props.scheme === 'light' ? props.theme.colors.black : props.theme.colors.white)};
    margin: 0 0 1.6rem;
    text-transform: uppercase;
    ${props => (props.paddingLeft ? `padding-left: ${props.paddingLeft};` : '')}
`;

export const Item = styled.li`
    font-family: chalet-new-york-sixty;
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    graphql(GET_STATISTICS, {
        options: (props: OutProps & InProps) => ({
            variables: {
                site: props.match.params.exchange,
            },
        }),
    })
);

export default enhance(DropdownStats);
