import gql from 'graphql-tag';
import { path } from 'ramda';
import React, { Fragment, SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import generatePath from 'react-router-dom/generatePath';
import { compose } from 'recompose';
import { connectedNetworksRoute, documentationRoute, infoRoute } from '../../constants/routes';
import sites, { Site } from '../../constants/sites';
import { StatsFragment, StatsQuery as Response } from '../../entities/operationResults';
import { getEntryOfType } from '../../services/entryTypes';
import { formatBitUnits } from '../../services/numberFormatting';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import Container from '../atoms/Container';
import { ExchangeParams } from '../Exchange';
import { InfoParams } from '../Info';
import SectionHeader from '../molecules/SectionHeader';
import StatBlock from '../molecules/StatBlock';
import { getStartedRoute } from '../../constants/routes';

type Props = DataProps<Response> & ThemeProps & RouteComponentProps<ExchangeParams>;

const GET_STATS_QUERY = gql`
    query Stats($site: [String!]) {
        exchangePage: entry(site: $site, section: "exchangePage") {
            ...Stats
        }
        vitals: globalSet(site: $site, handle: "globalStats") {
            ...on globalStats_GlobalSet {
                connectedNetworks
                peakTraffic
                colocations
            }
        }
        whereToConnectPage: entry(section: "menu", site: $site, title: "Where to connect") {
            ...WhereToConnectLink
        }
    }

    fragment WhereToConnectLink on menu_menu_Entry {
        linkEntry {
            uri
        }
    }

    fragment Stats on exchangePage_exchangePage_Entry {
        numbersCtaText
        hideColocations
    }
`;

const ColocationsAndNetworks: SFC<Props> = ({ data, match }) => {
    const exchangePage: StatsFragment | null = getEntryOfType('exchangePage_exchangePage_Entry', data.exchangePage);
    const peakTraffic = path<number>(['vitals', 'peakTraffic'], data) || 0;
    const peakTrafficFormatted = formatBitUnits(peakTraffic);
    const connectedNetworks = path<number>(['vitals', 'connectedNetworks'], data) || 0;
    const colocations = path<number>(['vitals', 'colocations'], data) || 0;
    const site: Site | undefined = sites.find(current => current.handle === match.params.exchange);
    const coloPath = generatePath<InfoParams>(infoRoute.path, { exchange: match.params.exchange, slug: 'colocations' });
    const statsPath = generatePath<InfoParams>(documentationRoute.path, {
        exchange: match.params.exchange,
        slug: 'total-stats',
    });
    const getStartedPath = generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange });

    return (
        <Container maxWidth marginTop={'2rem'} paddingTop={'8rem'} paddingBottom={'3rem'} paddingBottomL={'4rem'}>
            <SectionHeader
                title={
                    <Fragment>
                        <strong>{site ? site.title : ''}</strong> in numbers
                    </Fragment>
                }
            />
            {exchangePage && (
                <Row hasThreeBlocks={!!exchangePage.hideColocations}>
                    <StatBlock
                        title="Connected networks"
                        amount={connectedNetworks}
                        linkText="SEE NETWORKS"
                        linkUrl={generatePath<ExchangeParams>(connectedNetworksRoute.path, {
                            exchange: match.params.exchange,
                        })}
                        backgroundStyle={{ left: '-75%' }}
                    />
                    <StatBlock
                        title={`Peak (${peakTrafficFormatted.unit}/s)`}
                        amount={peakTrafficFormatted.amount}
                        linkText="SEE STATISTICS"
                        linkUrl={statsPath}
                        backgroundStyle={{ transform: 'rotate(-90deg)', right: '-66%', top: '-15%' }}
                    />
                    {!exchangePage.hideColocations && (
                        <StatBlock
                            title="Colocations"
                            amount={colocations}
                            linkText="SEE COLOCATIONS"
                            linkUrl={coloPath}
                            backgroundStyle={{ transform: 'rotate(-80deg)', right: '-70%', top: '15%' }}
                        />
                    )}
                    <StatBlock
                        title={exchangePage.numbersCtaText || 'Connect through 500+ locations worldwide'}
                        linkText="GET STARTED"
                        linkUrl={getStartedPath}
                        hasOrangeBackground
                        backgroundStyle={{ right: '-40%' }}
                        darkButton
                    />
                </Row>
            )}
        </Container>
    );
};

const Row = styled.div<{ hasThreeBlocks: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;

    > div {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 3.6rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueriesValues.s}px) and (max-width: ${props =>
            props.theme.mediaQueriesValues.l - 1}px) {
        > div {
            width: calc(50% - 1.8rem);
            margin-right: 3.6rem;

            &:nth-child(even) {
                margin-right: 0;
            }

            ${props =>
                props.hasThreeBlocks
                    ? `
                &:last-child {
                    width: 100%;
                    margin-right: 0;
                }
            `
                    : ``}
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        > div {
            width: ${props => (props.hasThreeBlocks ? `calc(33.33333% - 2.7rem)` : `calc(25% - 2.7rem);`)};
            margin-right: 3.6rem;
            margin-bottom: 0rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const enhance = compose(
    withRouter,
    graphql(GET_STATS_QUERY, {
        options: (props: Props) => ({
            variables: {
                site: props.match.params.exchange,
            },
        }),
    }),
    withTheme
);

export default enhance(ColocationsAndNetworks);
