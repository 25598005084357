import React, { SFC } from 'react';
import { DropdownStatsCompleteFragment, MenuScheme } from '../../entities/operationResults';
import { formatBitUnits } from '../../services/numberFormatting';
import styled from '../../styled-components';
import ChartGraph from './ChartGraph';

interface InProps {
    stats: DropdownStatsCompleteFragment;
}

const TrafficGraph: SFC<InProps> = ({ stats }) => {
    if (!stats) {
        return null
    }

    const peakIn = stats.peakIn !== null ? formatBitUnits(Number(stats.peakIn)) : null;
    const peakOut = stats.peakOut !== null ? formatBitUnits(Number(stats.peakOut)) : null;
    const averageIn = stats.averageIn !== null ? formatBitUnits(Number(stats.averageIn)) : null;
    const averageOut = stats.averageOut !== null ? formatBitUnits(Number(stats.averageOut)) : null;
    const currentIn = stats.currentIn !== null ? formatBitUnits(Number(stats.currentIn)) : null;
    const currentOut = stats.currentOut !== null ? formatBitUnits(Number(stats.currentOut)) : null;

    const averageInData = stats.averageInputData !== null ? stats.averageInputData.map(item => {
        return item !== null ? {
            ...item,
            time: item.time !== null ? Number(item.time) : null,
            value: item.value !== null ? Number(item.value) : null,
        } : null;
    }) : [];

    const averageOutData = stats.averageOutputData !== null ? stats.averageOutputData.map(item => {
        return item !== null ? {
            ...item,
            time: item.time !== null ? Number(item.time) : null,
            value: item.value !== null ? Number(item.value) : null,
        } : null;
    }) : [];

    const peakInData = stats.peakInputData ? stats.peakInputData.map(item => {
        return item !== null ? {
            ...item,
            time: item.time !== null ? Number(item.time) : null,
            value: item.value !== null ? Number(item.value) : null,
        } : null;
    }) : [];

    const peakOutData = stats.peakOutputData ? stats.peakOutputData.map(item => {
        return item !== null ? {
            ...item,
            time: item.time !== null ? Number(item.time) : null,
            value: item.value !== null ? Number(item.value) : null,
        } : null;
    }) : [];

    return (
        <TrafficGraphContainer>
            <Graph>
                <Heading>{stats.title}</Heading>
                <ChartGraph
                    averageIn={averageInData}
                    averageOut={averageOutData}
                    peakIn={peakInData}
                    peakOut={peakOutData}
                    showLegend={true}
                    scheme={MenuScheme.light}
                    interval={stats.interval}
                />
            </Graph>
            <StatsContainer>
                <Stats>
                    <Stat>
                        <StatTitle>Peak in</StatTitle>
                        <StatAmount>
                            {peakIn && peakIn.amount} <span>{peakIn && peakIn.unit}/s</span>
                        </StatAmount>
                    </Stat>
                    <Stat>
                        <StatTitle>Peak out</StatTitle>
                        <StatAmount>
                            {peakOut && peakOut.amount} <span>{peakOut && peakOut.unit}/s</span>
                        </StatAmount>
                    </Stat>
                    <Stat>
                        <StatTitle>Average in</StatTitle>
                        <StatAmount>
                            {averageIn && averageIn.amount} <span>{averageIn && averageIn.unit}/s</span>
                        </StatAmount>
                    </Stat>
                    <Stat>
                        <StatTitle>Average out</StatTitle>
                        <StatAmount>
                            {averageOut && averageOut.amount} <span>{averageOut && averageOut.unit}/s</span>
                        </StatAmount>
                    </Stat>
                    <Stat>
                        <StatTitle>Current in</StatTitle>
                        <StatAmount>
                            {currentIn && currentIn.amount} <span>{currentIn && currentIn.unit}/s</span>
                        </StatAmount>
                    </Stat>
                    <Stat>
                        <StatTitle>Current out</StatTitle>
                        <StatAmount>
                            {currentOut && currentOut.amount} <span>{currentOut && currentOut.unit}/s</span>
                        </StatAmount>
                    </Stat>
                </Stats>
            </StatsContainer>
        </TrafficGraphContainer>
    );
};

const TrafficGraphContainer = styled.section`
    display: flex;
    margin: 0 -1.6rem;
    flex-wrap: wrap;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        flex-wrap: nowrap;
    }
`;

const Graph = styled.div`
    width: 100%;
    margin: 0 1.6rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        flex: 2;
    }
`;

const StatsContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    margin: 0 1.6rem;
    width: 100%;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        width: 48rem;
        flex: 1;
    }
`;

const Stats = styled.ul`
    padding-left: 0;
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
`;

const Stat = styled.li`
    box-sizing: border-box;
    list-style: none;
    text-align: center;
    width: 50%;
    margin: 0 0 2.4rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        width: 33.333%;
        margin: 0 0 2.4rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        width: 50%;
        margin: 0 0 4.8rem;
    }
`;

const StatTitle = styled.div`
    font: ${props => props.theme.fonts.large.smallCaps};
    text-transform: uppercase;
    color: ${props => props.theme.colors.shuttleGray};

    margin-bottom: 1.2rem;
`;

const StatAmount = styled.div`
    font-family: chalet-new-york-sixty;
    font-size: 2rem;
    line-height: 2.4rem;

    > span {
        font-size: 1.4rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xs}) {
        font-size: 2.4rem;
    }
`;

const Heading = styled.h2<{ paddingLeft?: string }>`
    opacity: 0.5;
    font: ${props => props.theme.fonts.large.smallCaps};
    color: ${props => props.theme.colors.black};
    margin: 0 0 1.6rem;
    text-transform: uppercase;
    padding-left: 5.4rem;
`;

export default TrafficGraph;
