import React, {Fragment, SFC} from 'react';
import {ThemeProps, withTheme} from '../styled-components';
import Meta from './atoms/Meta';
import gql from 'graphql-tag';
import {compose, withState} from 'recompose';
import {DataProps, graphql} from 'react-apollo';
import {RouteConfigComponentProps} from 'react-router-config';
import {ExchangeParams} from './Exchange';
import {
    CitiesFragment,
    CountriesFragment,
    PartnerInfoFragment,
    PartnersQuery,
    PartnerTableRowFragment
} from '../entities/operationResults';
import {getEntriesOfType, getEntryOfType} from '../services/entryTypes';
import withWidth, {WidthProps} from '../hocs/withWidth';
import PartnerSlideover from './organisms/PartnerSlideover';
import CollectionTable from './molecules/CollectionTable';
import CollectionIntro from './organisms/collections/CollectionIntro';
import IntroTitle from './atoms/IntroTitle';
import ContentBody from './atoms/ContentBody';
import {getStartedRoute} from '../constants/routes';
import generatePath from 'react-router-dom/generatePath';
import * as queryString from 'querystring';
import CollectionFilters from './molecules/CollectionFilters';
import SpinnerPage from './molecules/SpinnerPage';

const GET_PARTNER_QUERY = gql`
    query Partners ($exchange: [String!], $country: [QueryArgument], $city: [QueryArgument], $search: String, $partnerType: [QueryArgument]) {
        partnerPage: entry(section: "partnerCollection", site: $exchange) {
            ...PartnerInfo
        }
        
        partnersTable: entries(section: "partner", site: $exchange, country: $country, city: $city, search: $search, partnerType: $partnerType, orderBy: "title ASC, country ASC, city ASC, datacenter ASC") {
            ...PartnerTableRow
        }
        
        countries: entries(section: "country", site: $exchange, orderBy: "title ASC") {
            ...Countries
        }
        
        cities: entries(section: "city", site: $exchange, country: $country, orderBy: "title ASC") {
            ...Cities
        }
    }
    
    fragment PartnerInfo on partnerCollectionSectionEntryUnion {
        ...on partnerCollection_partnerCollection_Entry {
            title
            introHeading
            introText
        }
    }
    
    fragment PartnerTableRow on partner_partner_Entry {
        id
        title
        datacenter
        city
        country
        partnerType
    }
    
    fragment Countries on country_country_Entry {
        title
    }
    
    fragment Cities on city_city_Entry {
        title
    }
`;

type Props = RouteConfigComponentProps<ExchangeParams> & DataProps<PartnersQuery> & WidthProps & ThemeProps & {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    setOpenPartnerName: (name: string) => void;
    openPartnerName: string;
    setOpenPartnerId: (id: number) => void;
    openPartnerId: string;
};

const columnWidths = {
    company: {
        l: '26%', m: '36%', s: '45%'
    },
    datacenter: {
        l: '26%', m: '36%', s: '45%'
    },
    location: {
        l: '26%', m: '0', s: '0'
    },
    type: {
        l: '12%', m: '12%', s: '0'
    },
    contact: {
        l: '10%', m: '16%', s: '10%'
    }
};

const Partners: SFC<Props> = ({ data, setWidth, width, theme, isModalOpen, setIsModalOpen, openPartnerName, setOpenPartnerName, openPartnerId, setOpenPartnerId, history, match, location }) => {
    const partnerPage: PartnerInfoFragment | null = getEntryOfType('partnerCollection_partnerCollection_Entry', data.partnerPage);
    const partnersTable: PartnerTableRowFragment[] | null = getEntriesOfType('partner_partner_Entry', data.partnersTable);
    const countries: CountriesFragment[] | null = getEntriesOfType('country_country_Entry', data.countries);
    const cities: CitiesFragment[] | null = getEntriesOfType('city_city_Entry', data.cities);

    const rowRenderer = (index: number) => {
        const partnerType = partnersTable[index].partnerType;
        const formattedType = partnerType === 'partner' ? 'Partner' : partnerType === 'colocation' ? 'Direct' : partnerType || '';
        const ctaProperties = {
            width: columnWidths.contact,
            cta: true,
            minWidth: '12rem'
        };
        const ctaColumn = partnerType === 'colocation' ? {
            ...ctaProperties,
            text: 'Get started',
            iconType: 'link',
            onClick: () => {
                history.push(generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange }));
            }
        } : {
            ...ctaProperties,
            text: 'Contact',
            iconType: 'popup',
            onClick: () => {
                setOpenPartnerId(Number(partnersTable[index].id) || 0);
                setOpenPartnerName(partnersTable[index].title || '');
                setIsModalOpen(true);
            }
        };
        return [
            {
                text: partnersTable[index].title || '',
                width: columnWidths.company
            },
            {
                text: partnersTable[index].datacenter || '',
                width: columnWidths.datacenter
            },
            {
                text: `${partnersTable[index].country}, ${partnersTable[index].city}`,
                width: columnWidths.location
            },
            {
                text: formattedType,
                width: columnWidths.type
            },
            ctaColumn
        ];
    };

    const headerItems = [
        {
            text: 'Company',
            width: columnWidths.company
        },
        {
            text: 'Data Center',
            width: columnWidths.datacenter
        },
        {
            text: 'Country, City',
            width: columnWidths.location
        },
        {
            text: 'Type',
            width: columnWidths.type
        },
        {
            text: 'Contact',
            width: columnWidths.contact,
            minWidth: '12rem'
        }
    ];

    if (!partnerPage) { return (<SpinnerPage />); }

    return (
        <Fragment>
            <Meta title={partnerPage.introHeading || partnerPage.title || ''} />
            <CollectionIntro>
                <div>
                    <IntroTitle
                        color={theme.colors.white}
                    >
                        {partnerPage.introHeading}
                    </IntroTitle>
                    {partnerPage.introText && partnerPage.introText && (
                        <ContentBody color={theme.colors.white} dangerouslySetInnerHTML={{ __html: partnerPage.introText }}/>
                    )}
                </div>
            </CollectionIntro>
            <CollectionFilters
                search
                countries={countries}
                cities={cities}
                types={[
                    { value: 'colocation', label: 'Direct' },
                    { value: 'partner', label: 'Partners' }
                ]}
                loading={data.loading}
            />
            <CollectionTable
                numOfRows={partnersTable.length || 0}
                getRowData={rowRenderer}
                headerItems={headerItems}
            />
            <PartnerSlideover
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                backgroundColor={theme.colors.mirage}
                textColor={theme.colors.white}
                partnerName={openPartnerName}
                partnerId={openPartnerId}
            />
        </Fragment>
    );
};

const enhance = compose(
    withWidth,
    withTheme,
    withState('isModalOpen', 'setIsModalOpen', false),
    withState('openPartnerName', 'setOpenPartnerName', ''),
    withState('openPartnerId', 'setOpenPartnerId', 0),
    graphql(GET_PARTNER_QUERY, {
        options: ({ match: { params: { exchange } }, location }: Props) => {
            const query = queryString.parse(location.search.replace('?', ''));
            return {
                variables: {
                    exchange,
                    ...query
                }
            };
        }
    })
);

export default enhance(Partners);