import React, { SFC } from 'react';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import { compose } from 'recompose';
import ObserveSize from 'react-observe-size';
import gql from 'graphql-tag';
import { DataProps, graphql } from 'react-apollo';
import { HeroFragment, HeroQuery as Response } from '../../entities/operationResults';
import { getEntryOfType } from '../../services/entryTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import { default as sites, Site } from '../../constants/sites';
import Meta from '../atoms/Meta';
import HeroIntroBlock from '../molecules/HeroIntroBlock';
import { Img } from 'react-image-loading';

const GET_HEROIMAGE_QUERY = gql`
    query Hero ($site: [String!]) {
        exchangePage: entries(site:$site, section: "exchangePage") {
            ...Hero
        }
    }

    fragment Hero on exchangePage_exchangePage_Entry {
        description
        heroImage {
            srcSmall: url(transform: "heroSmall")
            widthSmall: width(transform: "heroSmall")
            srcMedium: url(transform: "heroMedium")
            widthMedium: width(transform: "heroMedium")
            srcLarge: url(transform: "heroLarge")
            widthLarge: width(transform: "heroLarge")
            title
        }
    }
`;

interface Props extends ThemeProps, RouteComponentProps<ExchangeParams>, DataProps<Response> {
}

const enhanced = compose(
    withRouter,
    graphql(GET_HEROIMAGE_QUERY, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    }),
    withTheme
);

const Hero: SFC<Props> = ({ data, theme, match }) => {
    const exchangePage: HeroFragment | null = getEntryOfType('exchangePage_exchangePage_Entry', data.exchangePage && data.exchangePage[0]);
    const heroImage = exchangePage && exchangePage.heroImage && exchangePage.heroImage[0];
    const site: Site | undefined = sites.find(current => current.handle === match.params.exchange);
    if (!site) {
        throw new Error('Invalid exchange param passed to Hero parent route, this shouldn\'t happen');
    }

    return (
        <ObserveSize>
            {({ width }) =>
                <Container>
                    <Meta image={heroImage && heroImage.srcSmall} description={exchangePage && exchangePage.description} />
                    <HeroImageView>
                        <HeroImage
                            alt={heroImage && heroImage.title || undefined}
                            src={heroImage && heroImage.srcSmall || undefined}
                            srcSet={`
                                    ${heroImage && heroImage.srcSmall} ${heroImage && heroImage.widthSmall}w,
                                    ${heroImage && heroImage.srcMedium} ${heroImage && heroImage.widthMedium}w,
                                    ${heroImage && heroImage.srcLarge} ${heroImage && heroImage.widthLarge}w`}
                            sizes={'90vh'}
                        />
                    </HeroImageView>
                    <HeroFooter>
                        <HeroIntroBlock />
                    </HeroFooter>
                </Container>
            }
        </ObserveSize>
    );
};

const Container = styled.div`
    position: relative;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(220deg, rgba(0,0,0,0) 50%, rgba(23, 34, 42, 0.8));
        }
    }
`;
const HeroImageView = styled.div`
    width: 100%;
    height: 50vh;
    display: block;
    position: relative;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xs}) {
        min-height: 35vw;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        height: 60vh;
    }
`;
const HeroImage = styled(Img)`
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
`;
const HeroFooter = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: -1rem;
    position: relative;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        margin-top: -27.1rem;
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        margin-top: -44.2rem;
    }
`;

export default enhanced(Hero);
