import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import React, { SFC } from 'react';
import sites from '../constants/sites';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import Header from './organisms/Header';
import HeaderOffsetter from './atoms/HeaderOffsetter';
import { HeaderFragments } from './organisms/Header';
import Footer, { FooterFragments } from './organisms/Footer';
import gql from 'graphql-tag';
import { DataProps, graphql, withApollo, WithApolloClient } from 'react-apollo';
import { MenuQuery as Response } from '../entities/operationResults';
import { Redirect } from 'react-router';
import { primaryExchange } from '../constants/routes';
import Modal from './organisms/Modal';
import {withRouter} from "react-router-dom";

export interface ExchangeParams {
    exchange: string;
}

interface Props extends RouteConfigComponentProps<ExchangeParams>, DataProps<Response>, WithApolloClient<{}> {
}
const GET_MENUS_QUERY = gql`
    query Menu ($site: [String!]) {
        menuItems: entries(site: $site, section: "menu", level: 1) {
            ...Menu
        }

        menuConfig @client {
            scheme
        }

        globalSet(site: "ams", handle: "exchangeConfig") {
            ...on exchangeConfig_GlobalSet {
                loginUrl
                mailChimpSubscriptionUrl
                footerContactFormText
            }
            ...on modalSettings_GlobalSet {
                showModal
                modalTitle
                modalText
                modalEntry {
                  title
                  uri
                }
            }
            ...on socialMedia_GlobalSet {
                ...FooterSocials
            }
        }
        footerLinks: entries(site:$site, section: "footerLink") {
            ...FooterLink
        }
    }

    fragment FooterSocials on socialMedia_GlobalSet {
        facebookUrl
        linkedinUrl
        twitterUrl
        youtubeUrl
    }
    
    ${HeaderFragments}
    ${FooterFragments}
`;

const Exchange: SFC<Props> = ({ route, data, location }) => {
    if (!route) {
        throw new Error('No route prop was passed to Exchange, this shouldn\'t happen!');
    }

    return (
        <React.Fragment>
            <Header data={data}/>
            {((location.pathname).match(/\//g) || []).length === 1 && <Modal data={data}/>}
            <HeaderOffsetter>
                {renderRoutes(route.routes)}
                <Footer data={data}/>
            </HeaderOffsetter>
        </React.Fragment>
    );
};

const enhance = compose(
    branch(
        ({ match }: Props) => !sites.find(curr => curr.handle === match.params.exchange),
        renderComponent(() => <Redirect to={`/${primaryExchange && primaryExchange.handle}`} push={false} />)
    ),
    graphql(GET_MENUS_QUERY, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    }),
    withApollo,
    withRouter,
    lifecycle<Props, {}, {} >({
        componentDidUpdate(prevProps: Props) {
            if (this.props.match.params.exchange !== prevProps.match.params.exchange) {
                this.props.client.resetStore();
            }
        }
    })
);

export default enhance(Exchange);
