import gql from 'graphql-tag';
import { ExchangeParams } from './Exchange';
import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import { DataProps, graphql } from 'react-apollo';
import {
    SingleNewsPageQuery as Response, SingleNewsPageFragment
} from '../entities/operationResults';
import { branch, compose, renderComponent } from 'recompose';
import Container from './atoms/Container';
import NotFound from './NotFound';
import { default as ContentBlocks, contentBlockFragments } from './organisms/ContentBlocks';
import Meta from './atoms/Meta';
import AuthorInfo from './molecules/AuthorInfo';
import { ThemeProps } from '../styled-components';
import RelatedDetail, { relatedFragments } from './organisms/RelatedDetail';
import DetailWrapper from './atoms/DetailWrapper';
import HeroNews from './molecules/HeroNews';
import withSpinner from '../hocs/withSpinner';

import { format } from 'date-fns';

const formatDate = (dateString: string, formatString = "DD MMMM YYYY", timezone = "Europe/Amsterdam") => {
    const date = new Date(dateString);
    // You can use a library like 'date-fns-tz' for timezone handling if needed
    return format(date, formatString);
};

const GET_NEWS_QUERY = gql`
    query SingleNewsPage ($exchange: [String!], $slug: [String!]) {
        entry(section: "news", site: $exchange, slug: $slug) {
            ...SingleNewsPage
        }
    }

    fragment SingleNewsPage on news_news_Entry {
        ...SingleNews
        relatedItems {
            ...Related
        }
    }

    fragment SingleNews on news_news_Entry {
        title
        description
        postDate
        heroImage {
            src: url(transform: "newsThumbnail")
            width: width(transform: "newsThumbnail")
            src2x: url(transform: "newsThumbnail2x")
            width2x: width(transform: "newsThumbnail2x")
            title
        }
        author {
            ... on User {
                name
                functionTitle
                photo {
                    url(transform: "newsThumbnail")
                    title
                }
            }
        }
        contentBlocks {
            ...ContentBlock
        }
    }
    
    ${relatedFragments}
    ${contentBlockFragments}
`;

interface StoryParams extends ExchangeParams {
    slug: string;
}

type Props = RouteComponentProps<StoryParams> & DataProps<Response> & ThemeProps;

const News: SFC<Props> = ({ data: { entry } }) => {
    const news: SingleNewsPageFragment | null = getEntryOfType('news_news_Entry', entry);
    if (!news) { return null; }
    const heroImage = news.heroImage && news.heroImage[0];
    const postDate = formatDate(news.postDate || '', "DD MMMM YYYY", "Europe/Amsterdam");
    const relatedItems = getRelatedItems(news.relatedItems);

    return (
        <Fragment>
            <Meta title={news.title || ''} description={news.description} image={`${heroImage && heroImage.src}`} />
            <HeroNews
                img={heroImage ? {
                    src: heroImage.src,
                    width: heroImage.width !== null ? heroImage.width.toString() : null,
                    src2x: heroImage.src2x,
                    width2x: heroImage.width2x !== null ? heroImage.width2x.toString() : null,
                    title: heroImage.title
                } : undefined}
                title={news.title || ''}
                subTitle="AMS-IX NEWS"
            />
            <DetailWrapper>
                <Container maxWidth paddingTop="4rem" paddingTopL="8rem">
                    <AuthorInfo author={news.author} postDate={postDate}/>
                </Container>
                <ContentBlocks contentBlocks={news.contentBlocks} />
            </DetailWrapper>
            {news.relatedItems && news.relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
        </Fragment>
    );
};

const enhance = compose(
    graphql(GET_NEWS_QUERY, {
        options: ({ match: { params: { slug, exchange } } }: Props) => ({
            variables: {
                slug,
                exchange
            }
        })
    }),
    withSpinner,
    branch(
        ({ data }: Props) => !data.entry,
        renderComponent(NotFound)
    ),
);

export default enhance(News);