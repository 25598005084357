import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import {
    DocumentationCollectionItemFragment,
    EventCollectionItemFragment,
    InfoCollectionItemFragment,
    NewsCollectionItemFragment,
    NewsletterCollectionItemFragment,
    ServiceCollectionItemFragment,
    StoryCollectionItemFragment,
} from '../../../entities/operationResults';
import formatEventDate from '../../../services/formatEventDate';
import styled from '../../../styled-components';
import LazyImg from '../../atoms/LazyImg';

import { format } from 'date-fns';

const formatDate = (dateString: string, formatString = "DD MMMM YYYY", timezone = "Europe/Amsterdam") => {
    const date = new Date(dateString);
    // You can use a library like 'date-fns-tz' for timezone handling if needed
    return format(date, formatString);
};

const newsFragment = gql`
    fragment NewsCollectionItem on news_news_Entry {
        id
        uri
        title
        description
        heroImage {
            src: url(transform: "collectionItem")
            width(transform: "collectionItem")
            src2x: url(transform: "collectionItem2x")
            width2x: width(transform: "collectionItem2x")
            title
        }
        date: postDate
    }
`;

const newsletterFragment = gql`
    fragment NewsletterCollectionItem on newsletter_newsletter_Entry {
        id
        uri
        title
        newsletterUrl
        newsletterDate
        heroImage {
            src: url(transform: "collectionItem")
            width(transform: "collectionItem")
            src2x: url(transform: "collectionItem2x")
            width2x: width(transform: "collectionItem2x")
            title
        }
        date: postDate
    }
`;

const eventFragment = gql`
    fragment EventCollectionItem on event_event_Entry {
        id
        uri
        title
        description
        heroImage {
            src: url(transform: "collectionItem")
            width(transform: "collectionItem")
            src2x: url(transform: "collectionItem2x")
            width2x: width(transform: "collectionItem2x")
            title
        }
        startDate
        endDate
        date: postDate
        listInPastEvents
        location
    }
`;

const storyFragment = gql`
    fragment StoryCollectionItem on story_story_Entry {
        id
        uri
        title
        description
        heroImage {
            src: url(transform: "collectionItem")
            width(transform: "collectionItem")
            src2x: url(transform: "collectionItem2x")
            width2x: width(transform: "collectionItem2x")
            title
        }
        date: postDate
    }
`;

const infoFragment = gql`
    fragment InfoCollectionItem on info_info_Entry {
        id
        uri
        title
        description
    }
`;

const documentationFragment = gql`
    fragment DocumentationCollectionItem on documentation_documentation_Entry {
        id
        uri
        title
        description
    }
`;

const serviceFragment = gql`
    fragment ServiceCollectionItem on service_service_Entry {
        id
        uri
        title
        description
        heroImage: icon {
            src: url(transform: "serviceLogo")
            width(transform: "serviceLogo")
            src2x: url(transform: "serviceLogo2x")
            width2x: width(transform: "serviceLogo2x")
            title
        }
    }
`;

export const fragments = {
    news: newsFragment,
    newsletter: newsletterFragment,
    story: storyFragment,
    event: eventFragment,
    info: infoFragment,
    documentation: documentationFragment,
    service: serviceFragment,
};

interface Props {
    collectionItem:
        | NewsCollectionItemFragment
        | NewsletterCollectionItemFragment
        | StoryCollectionItemFragment
        | EventCollectionItemFragment
        | InfoCollectionItemFragment
        | DocumentationCollectionItemFragment
        | ServiceCollectionItemFragment;
}

const CollectionItem: SFC<Props> = ({ collectionItem }) => {
    const thumb = collectionItem.__typename !== 'info_info_Entry' && collectionItem.__typename !== 'documentation_documentation_Entry' && collectionItem.heroImage && collectionItem.heroImage[0];
    const date = collectionItem.__typename === 'event_event_Entry' && collectionItem.startDate
        ? formatEventDate(formatDate(collectionItem.startDate, "DD MMMM YYYY", "Europe/Amsterdam"), collectionItem.endDate ? formatDate(collectionItem.endDate, "DD MMMM YYYY", "Europe/Amsterdam") : null)
        : (collectionItem.__typename === 'news_news_Entry' || collectionItem.__typename === 'story_story_Entry') && formatDate(collectionItem.date || '', "DD MMMM YYYY", "Europe/Amsterdam");
    const newsletterDate = collectionItem.__typename === 'newsletter_newsletter_Entry' && formatDate(collectionItem.newsletterDate || '', "DD MMMM YYYY", "Europe/Amsterdam")
    if (collectionItem.__typename === 'newsletter_newsletter_Entry'){
        return (
                <ListItemExternalLink type={collectionItem.__typename} href={collectionItem.newsletterUrl && collectionItem.newsletterUrl || '/'} target="_blank">
                    {thumb && thumb.src && (
                        <ImgWrapper>
                            <LazyImg
                                alt={thumb.title || undefined}
                                src={thumb.src}
                                srcSet={`${thumb.src} ${thumb.width}w, ${thumb.src2x} ${thumb.width2x}w`}
                                sizes={'(min-width: 990px) 10vw, 140px'}
                            />
                        </ImgWrapper>
                    )}
                    <Copy>
                        <DateParagraph>{newsletterDate}</DateParagraph>
                        <Title>{collectionItem.title}</Title>
                    </Copy>
                </ListItemExternalLink>
        );
    } else {
        return (
            <li>
                <ListItemLink type={collectionItem.__typename} to={`/${collectionItem.uri}`}>
                    {thumb && thumb.src && (
                        <ImgWrapper>
                            <LazyImg
                                alt={thumb.title || undefined}
                                src={thumb.src}
                                srcSet={`${thumb.src} ${thumb.width}w, ${thumb.src2x} ${thumb.width2x}w`}
                                sizes={'(min-width: 990px) 10vw, 140px'}
                            />
                        </ImgWrapper>
                    )}
                    <Copy>
                        {collectionItem.__typename !== 'story_story_Entry' && (<DateParagraph>{date}</DateParagraph>)}
                        <Title>{collectionItem.title}</Title>
                        {collectionItem.__typename === 'event_event_Entry' && <Subtitle>{collectionItem.location}</Subtitle>}
                        <Excerpt>{collectionItem.description}</Excerpt>
                    </Copy>
                </ListItemLink>
            </li>
        );
    }
};

const ImgWrapper = styled.div`
    min-height: 100%;
    position: relative;
    overflow: hidden;
    margin-right: 3.1rem;

    img {
        object-position: center;
        object-fit: cover;
    }
`;

const ListItemLink = styled(Link)<
    LinkProps & { type: 'news_news_Entry' | 'story_story_Entry' | 'event_event_Entry' | 'info_info_Entry' | 'documentation_documentation_Entry' | 'service_service_Entry' | 'newsletter_newsletter_Entry'}
>`
    border-bottom: 0.1rem solid ${props => props.theme.colors.mystic};
    display: flex;
    flex-direction: column;
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;

    &:hover {
        img {
            transform: ${props => (props.type === 'news_news_Entry' ? 'scale(1)' : 'scale(1.05)')};
        }

        h2 {
            color: ${props => props.theme.colors.yellowOrange};
        }
    }

    ${ImgWrapper} {
        min-width: ${props => (props.type === 'news_news_Entry' ? 'initial' : '100%')};
        width: ${props => (props.type === 'news_news_Entry' ? '14rem' : 'initial')};
        height: ${props => (props.type === 'news_news_Entry' ? '14rem' : '16rem')};
      
        > div {
          width: 100%;
          height: 100%;
        }

        img {
            border-radius: ${props => (props.type === 'news_news_Entry' ? '50%' : '0%')};
            margin-bottom: ${props => (props.type === 'news_news_Entry' ? '' : '1.5rem')};
            flex-shrink: 0;
            transition: 0.5s transform ease-in-out;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        flex-direction: unset;
        padding: ${props => (props.type === 'news_news_Entry' ? '0 0 5.6rem 0' : '0 0 5.6rem 0')};
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: ${props => (props.type === 'news_news_Entry' ? '14rem' : '28.6rem')};
            height: ${props => (props.type === 'news_news_Entry' ? '14rem' : '18rem')};
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: ${props => (props.type === 'news_news_Entry' ? '0 12.3rem 5.6rem 12.3rem' : '0 12.3rem 5.6rem 12.3rem')};
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: ${props => (props.type === 'news_news_Entry' ? '14rem' : '33.6rem')};
            height: ${props => (props.type === 'news_news_Entry' ? '14rem' : '21rem')};
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: ${props => (props.type === 'news_news_Entry' ? '0 23rem 5.6rem 12.3rem' : '0 23rem 5.6rem 12.3rem')};
        margin-bottom: 5.6rem;
    }
`;

const ListItemExternalLink = styled.a<{ type: 'newsletter_newsletter_Entry'}>`
    border-bottom: 0.1rem solid ${props => props.theme.colors.mystic};
    display: flex;
    flex-direction: column;
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;

    &:hover {
        img {
            transform: scale(1);
        }

        h2 {
            color: ${props => props.theme.colors.yellowOrange};
        }
    }

    ${ImgWrapper} {
        min-width: initial;
        width: 14rem;
        height: 14rem;

        > div {
            width: 100%;
            height: 100%;
        }

        img {
            border-radius: 50%;
            margin-bottom: 0;
            flex-shrink: 0;
            transition: 0.5s transform ease-in-out;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        flex-direction: unset;
        padding: 0 0 5.6rem 0;
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: 14rem;
            height: 14rem;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 0 12.3rem 5.6rem 12.3rem;
        margin-bottom: 5.6rem;
        ${ImgWrapper} {
            min-width: 14rem;
            height: 14rem;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        padding: 0 23rem 5.6rem 12.3rem;
        margin-bottom: 5.6rem;
    }
`;

const Copy = styled.div`
    max-width: 70.4rem;
`;

const DateParagraph = styled.p`
    margin: 0;
    font: ${props => props.theme.fonts.large.eventDate};
    color: ${props => props.theme.colors.tango};
    text-transform: uppercase;
`;

const Title = styled.h2`
    font: ${props => props.theme.fonts.small.sectionItemTitle};
    margin: 0;
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.medium.sectionItemTitle};
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.sectionItemTitle};
    }
`;

const Subtitle = styled(Title)`
    font-family: chalet-london-sixty, sans-serif !important;
`;

const Excerpt = styled.p`
    margin: 1.6rem 0 0 0;
    font: ${props => props.theme.fonts.small.contentBody};
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.large.contentBody};
    }
`;

export default CollectionItem;
