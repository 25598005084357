import gql from 'graphql-tag';
import React, { SFC } from 'react';

import { ButtonContentBlockFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import Container from '../atoms/Container';
import ContentBody from '../atoms/ContentBody';

export const fragment = gql`
    fragment ButtonContentBlock on contentBlocks_button_BlockType {
        id
        label
        linkEntry {
            uri
        }
    }
`;

interface Props {
    contentBlock: ButtonContentBlockFragment;
}

const ButtonContentBlock: SFC<Props> = ({ contentBlock: { id, label, linkEntry } }) => {
    const linkInButton = linkEntry && linkEntry[0];

    return linkInButton ? (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'} marginTop={'-3rem'} marginTopL={'-6.6rem'}>
            <Block>
                <DefaultButton colorScheme={'dark'} to={`/${linkInButton.uri || ''}`}>
                    <span>{label}</span>
                </DefaultButton>
            </Block>
        </Container>
    ) : null;
};

export const Block = styled.div`
    box-sizing: border-box;

    ${ContentBody} p:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;

        > h2 {
            max-width: 60%;
        }
    }
`;

export default ButtonContentBlock;
