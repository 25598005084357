import React from 'react';
import styled from '../../styled-components';
import { StoryFragment } from '../../entities/operationResults';
import { pathOr } from 'ramda';
import placeholderAvatar from '../../assets/ams-ix-avatar.svg';
import { Img } from 'react-image-loading';

interface Props {
    postDate?: string | null;
    author: StoryFragment['author'];
}

const AuthorInfo = ({ postDate, author }: Props) => {
    const authorPhotoURL = pathOr(placeholderAvatar, ['photo', 'url'], author);
    return (
        <AuthorWrapper>
            <AuthorImage><Avatar src={authorPhotoURL} alt={author && author.name || ''} /></AuthorImage>
            <Info>
                {author && author.name && <AuthorName>{author.name}</AuthorName>}
                {author && author.functionTitle && <p>{author.functionTitle}</p>}
                <p>{postDate}</p>
            </Info>
        </AuthorWrapper>
    );
};

const AuthorWrapper = styled.div`
    display: flex;
    align-items: center;
    line-height: 2.4rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
    }
`;

const Avatar = styled(Img)`
    width: 5.6rem;
    height: 5.6rem;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        width: 7.2rem;
        height: 7.2rem;
    }
`;

const Info = styled.div`
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: ${(props) => props.theme.colors.mirage};
    
    p {
      margin: 1rem 0;
    }
`;

const AuthorName = styled.p`
    font: ${props => props.theme.fonts.large.contentTitle};
    font-size: inherit;
    line-height: inherit;
    color: ${(props) => props.theme.colors.black};
`;

const AuthorImage = styled.div`
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2rem;
    line-height: 0;
    position: relative;
`;

export default AuthorInfo;