import React, { Fragment, SFC } from 'react';
import { NewsItemsFragment, NewsQuery as Response } from '../../entities/operationResults';
import SectionHeader from '../molecules/SectionHeader';
import Container from '../atoms/Container';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import NewsPreview from '../molecules/NewsPreview';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { DataProps, graphql } from 'react-apollo';
import { getEntriesOfType } from '../../services/entryTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import generatePath from 'react-router-dom/generatePath';
import { newsRoute } from '../../constants/routes';

import { format } from 'date-fns';

const formatDate = (dateString: string, formatString = "DD MMMM YYYY", timezone = "Europe/Amsterdam") => {
    const date = new Date(dateString);
    // You can use a library like 'date-fns-tz' for timezone handling if needed
    return format(date, formatString);
};

const GET_NEWS_QUERY = gql`
    query News ($site: [String!]) {
        news: entries(site:$site, section: "news", limit:4) {
            ...NewsItems
        }
    }
    fragment NewsItem on news_news_Entry {
        id
        uri
        title
        heroImage{
            src: url(transform: "newsThumbnail")
            width: width(transform: "newsThumbnail")
            src2x: url(transform: "newsThumbnail2x")
            width2x: width(transform: "newsThumbnail2x")
            title
        }
        postDate
    }

    fragment NewsItems on news_news_Entry {
        ...NewsItem
    }
`;

interface Props extends DataProps<Response>, ThemeProps, RouteComponentProps<ExchangeParams> {
}

const enhance = compose(
    withRouter,
    graphql(GET_NEWS_QUERY, {
        options: ((props: Props) => ({
            variables: {
                site: props.match.params.exchange
            }
        }))
    }),
    withTheme
);

const LatestNews: SFC<Props> = ({ data, theme, match }) => {
    const news: NewsItemsFragment[] = getEntriesOfType('news_news_Entry', data.news);
    return (
        <Container backgroundColor={theme.colors.aquaHaze} paddingTop={'5rem'} paddingTopL={'10rem'} paddingBottom={'5rem'} paddingBottomL={'8.8rem'} maxWidth>
            <SectionHeader
                title={<Fragment>Latest <strong>news</strong></Fragment>}
                linkLabel={'ALL NEWS'}
                link={generatePath(newsRoute.path, { exchange: match.params.exchange })}
            />
            <Newswrap>
                {news.map(({ id, title, uri, heroImage, postDate }) => {
                    const firstImage = heroImage && heroImage[0];
                    return uri && (
                        <NewsPreview
                            link={`/${uri}`}
                            key={id || ''}
                            img={firstImage ? {
                                src: firstImage.src,
                                width: firstImage.width ? firstImage.width.toString() : null,
                                src2x: firstImage.src2x,
                                width2x: firstImage.width2x ? firstImage.width2x.toString() : null,
                                title: firstImage.title
                            } : undefined}
                            date={postDate && formatDate(postDate, "DD MMMM YYYY", "Europe/Amsterdam")}
                        >
                            {title}
                        </NewsPreview>
                    );
                })}
            </Newswrap>
        </Container>
    );
};

const Newswrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default enhance(LatestNews);