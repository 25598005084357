import gql from 'graphql-tag';
import React, { Fragment, SFC } from 'react';
import {
    ApplicationFormContentBlockFragment,
    ButtonContentBlockFragment,
    CodeContentBlockFragment,
    ContactFormContentBlockFragment,
    GraphicContentBlockFragment,
    ImageContentBlockFragment,
    IntroContentBlockFragment,
    LogosContentBlockFragment,
    TableContentBlockFragment,
    TextContentBlockFragment,
    TextWithImageContentBlockFragment,
    TrafficGraphContentBlockFragment,
    UsersContentBlockFragment,
    VideoContentBlockFragment,
    AudioContentBlockFragment,
    RegistrationFormContentBlockFragment,
} from '../../entities/operationResults';
import styled from '../../styled-components';
import ApplicationFormContentBlock, {
    fragment as applicationFormFragment,
} from '../molecules/ApplicationFormContentBlock';
import ButtonContentBlock, { fragment as buttonFragment } from '../molecules/ButtonContentBlock';
import CodeContentBlock, { fragment as codeFragment } from '../molecules/CodeContentBlock';
import ContactFormContentBlock, { fragment as contactFormFragment } from '../molecules/ContactFormContentBlock';
import GraphicContentBlock, { fragment as graphicFragment } from '../molecules/GraphicContentBlock';
import ImageContentBlock, { fragment as imageFragment } from '../molecules/ImageContentBlock';
import IntroContentBlock, { fragment as introFragment } from '../molecules/IntroContentBlock';
import LogosContentBlock, { fragment as logosFragment } from '../molecules/LogosContentBlock';
import TableContentBlock, { fragment as tableFragment } from '../molecules/TableContentBlock';
import TextContentBlock, { fragment as textFragment } from '../molecules/TextContentBlock';
import TextWithImageContentBlock, { fragment as textWithImageFragment } from '../molecules/TextWithImageContentBlock';
import TrafficGraphContentBlock, { fragment as trafficGraphFragment } from '../molecules/TrafficGraphContentBlock';
import UsersContentBlock, { fragment as usersFragment } from '../molecules/UsersContentBlock';
import VideoContentBlock, { fragment as videoFragment } from '../molecules/VideoContentBlock';
import AudioContentBlock, { fragment as audioFragment } from '../molecules/AudioContentBlock';
import RegistrationFormContentBlock, { fragment as registrationFormFragment } from '../molecules/RegistrationFormContentBlock';

export const contentBlockFragments = gql`
    fragment ContentBlock on contentBlocks_MatrixField {
        ...TextContentBlock
        ...ImageContentBlock
        ...TableContentBlock
        ...CodeContentBlock
        ...TextWithImageContentBlock
        ...ContactFormContentBlock
        ...VideoContentBlock
        ...AudioContentBlock
        ...IntroContentBlock
        ...ButtonContentBlock
        ...LogosContentBlock
        ...GraphicContentBlock
        ...TrafficGraphContentBlock
        ...UsersContentBlock
        ...ApplicationFormContentBlock
        ...RegistrationFormContentBlock
    }

    ${textFragment}
    ${imageFragment}
    ${tableFragment}
    ${codeFragment}
    ${textWithImageFragment}
    ${contactFormFragment}
    ${videoFragment}
    ${audioFragment}
    ${introFragment}
    ${buttonFragment}
    ${logosFragment}
    ${graphicFragment}
    ${trafficGraphFragment}
    ${usersFragment}
    ${applicationFormFragment}
    ${registrationFormFragment}
`;

interface SectionEndingBlock {
    __typename: 'contentBlocks_sectionEnding_BlockType';
}

export type ContentBlock =
    | ImageContentBlockFragment
    | TextContentBlockFragment
    | TableContentBlockFragment
    | CodeContentBlockFragment
    | TextWithImageContentBlockFragment
    | SectionEndingBlock
    | ContactFormContentBlockFragment
    | VideoContentBlockFragment
    | AudioContentBlockFragment
    | IntroContentBlockFragment
    | ButtonContentBlockFragment
    | LogosContentBlockFragment
    | GraphicContentBlockFragment
    | TrafficGraphContentBlockFragment
    | UsersContentBlockFragment
    | ApplicationFormContentBlockFragment
    | RegistrationFormContentBlockFragment
    | null;

interface Props {
    contentBlocks: ContentBlock[] | null;
    narrow?: boolean;
}

const ContentBlocks: SFC<Props> = ({ contentBlocks, narrow }) => (
    <Fragment>
        {contentBlocks &&
            splitIntoSections(contentBlocks).map((blocks, index) => (
                <Section key={index} odd={index % 2 === 1} first={index === 0} narrow={narrow}>
                    {blocks.map((block, i) => renderBlock(block, i, narrow))}
                </Section>
            ))}
    </Fragment>
);

const splitIntoSections = (contentBlocks: ContentBlock[]): ContentBlock[][] => {
    const sections: ContentBlock[][] = [];
    let blocks = [...contentBlocks];

    while (blocks.find(block => !!block && block.__typename === 'contentBlocks_sectionEnding_BlockType')) {
        const sectionIndex = blocks.findIndex(block => !!block && block.__typename === 'contentBlocks_sectionEnding_BlockType');
        sections.push(blocks.slice(0, sectionIndex));
        blocks = blocks.slice(sectionIndex + 1);
    }
    if (blocks.length) {
        sections.push(blocks);
    }
    return sections;
};

const renderBlock = (contentBlock: ContentBlock, index: number, narrow?: boolean) => {
    if (!contentBlock) {
        return null;
    }

    switch (contentBlock.__typename) {
        case 'contentBlocks_text_BlockType':
            return <TextContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_image_BlockType':
            return <ImageContentBlock key={index} contentBlock={contentBlock} narrow={narrow} />;
        case 'contentBlocks_table_BlockType':
            return <TableContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_codeSnippet_BlockType':
            return <CodeContentBlock key={index} contentBlock={contentBlock} narrow={narrow} />;
        case 'contentBlocks_textWithImage_BlockType':
            return <TextWithImageContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_contactForm_BlockType':
            return <ContactFormContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_video_BlockType':
            return <VideoContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_audio_BlockType':
            return <AudioContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_intro_BlockType':
            return <IntroContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_button_BlockType':
            return <ButtonContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_logos_BlockType':
            return <LogosContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_graphic_BlockType':
            return <GraphicContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_trafficGraph_BlockType':
            return <TrafficGraphContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_users_BlockType':
            return <UsersContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_applicationForm_BlockType':
            return <ApplicationFormContentBlock key={index} contentBlock={contentBlock} />;
        case 'contentBlocks_registrationForm_BlockType':
            return <RegistrationFormContentBlock key={index} contentBlock={contentBlock} />;
        default:
            console.warn(`WARN: attempted to render contentBlock of unknown type '${contentBlock.__typename}'`);
            return null;
    }
};

const Section = styled.section<{ odd: boolean; first: boolean; narrow?: boolean }>`
    background-color: ${({ odd, theme }) => (odd ? '#F7F9FA' : 'inherit')};
    padding-top: 4rem;
    ${({ narrow }) => (narrow ? 'padding-right: 32rem' : '')}

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 8.8rem;
    }

    @media screen and (max-width: ${props => props.theme.mediaQueries.l}) {
        padding-right: 0;
    }
`;

export default ContentBlocks;
