import React, { SFC } from 'react';
import Scroll from 'react-scroll';
import { ServicePageBenefitFragment } from '../../entities/operationResults';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import { compose } from 'recompose';
import { path } from 'ramda';
import ContentTitle from '../atoms/ContentTitle';
import gql from 'graphql-tag';
import LazyImg from '../atoms/LazyImg';

export const serviceBenefitsFragment = gql`
    fragment ServicePageBenefit on benefits_benefit_BlockType {
        icon {
            url
        }
        heading
        description
    }
`;

interface InProps {
    benefits: Array<ServicePageBenefitFragment | null>;
    serviceTitle: string;
}

const ServiceBenefits: SFC<InProps & ThemeProps> = ({ benefits, serviceTitle, theme }) => {
    return (
        <Scroll.Element name={'benefits'}>
            <ContentTitle color={theme.colors.white}>Benefits of {serviceTitle}</ContentTitle>
            <Wrapper>
                {benefits.map((benefit, index) => {
                    if (!benefit) {
                        return null;
                    }
                    const image = path<string>(['icon', 0, 'url'], benefit);
                    return (
                        <Benefit key={index}>
                            {image && (
                                <Image>
                                    <LazyImg src={image} alt={benefit.heading || ''} />
                                </Image>
                            )}
                            <Title>{benefit.heading}</Title>
                            <Description>{benefit.description}</Description>

                        </Benefit>
                    );
                })}
            </Wrapper>
        </Scroll.Element>
    );
};

const Title = styled.h2`
    margin: 0 0 1.6rem 0;
    font: ${props => props.theme.fonts.small.sectionItemTitle};
    color: ${props => props.theme.colors.white};
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.large.sectionItemTitle};
    }
`;

const Description = styled.div`
    font: ${props => props.theme.fonts.large.storySubtitle};
    color: ${props => props.theme.colors.baliHai};
    margin-bottom: 2rem;
`;

const Benefit = styled.div`
    margin-bottom: 3.4rem;
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        width: 40%;
        margin-right: 10%;
    }
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        width: 26%;
        margin-right: 10%;
        margin-bottom: 6.4rem;
        
        &:nth-child(3n) {
            margin-right: 2%;
        }
    }
`;

const Image = styled.div`
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 3.2rem;
    position: relative;
    display: flex;
    align-items: center;
    
    img {
        max-width: 100%;
        display: block;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    margin-top: 4.8rem;
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        flex-direction: row;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        margin-top: 8rem;
    }
`;

const enhanced = compose<ThemeProps, InProps>(
    withTheme
);

export default enhanced(ServiceBenefits);