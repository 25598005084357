import { ContactInfoSocialsFragment } from '../../entities/operationResults';
import * as React from 'react';
import { SFC } from 'react';
import styled from '../../styled-components';
import { IconButtonLink } from '../atoms/buttons/IconButton';
import Twitter from '../atoms/icons/Twitter';
import LinkedIn from '../atoms/icons/LinkedIn';
import Youtube from '../atoms/icons/Youtube';
import Facebook from '../atoms/icons/Facebook';
import gql from 'graphql-tag';

export const contactInfoFragment = gql`
    fragment ContactInfoSocials on socialMedia_GlobalSet {
        facebookUrl
        linkedinUrl
        twitterUrl
        youtubeUrl
    }
`;

interface Props {
    socials: ContactInfoSocialsFragment;
    contactInfo: string;
    address: string;
}

const ContactInfo: SFC<Props> = ({ contactInfo, address, socials }) => (
    <Info>
        <h3>
            Amsterdam Internet Exchange
        </h3>
        <p>{address}</p>
        <h3>Get in touch</h3>
        <p>{contactInfo}</p>
        <h3>Follow us</h3>
        <div>
            {socials.facebookUrl && <IconButtonLink target="_blank" href={socials.facebookUrl} colorScheme="subtle" icon={<Facebook />} />}
            {socials.twitterUrl && <IconButtonLink target="_blank" href={socials.twitterUrl} colorScheme="subtle" icon={<Twitter />} />}
            {socials.linkedinUrl && <IconButtonLink target="_blank" href={socials.linkedinUrl} colorScheme="subtle" icon={<LinkedIn />} />}
            {socials.youtubeUrl && <IconButtonLink target="_blank" href={socials.youtubeUrl} colorScheme="subtle" icon={<Youtube />} />}
        </div>
    </Info>
);

const Info = styled.div`
    margin: 3rem 0;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        max-width: 320px;
        margin-left: 15rem;
        margin-top: 6rem;
    }

    h3 {
        color: ${props => props.theme.colors.baliHai};
        font: ${props => props.theme.fonts.large.storyTitle};
        text-transform: uppercase;
        font-weight: 600;
        
        &:not(:first-child) {
            margin-top: 5.5rem;
        }
    }

    p {
        margin: 0;
        white-space: pre;
        font: 1.6rem/2.6rem chalet-london-sixty, sans-serif;
    }
`;

export default ContactInfo;