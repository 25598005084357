import { default as React, SFC } from 'react';
import Helmet from 'react-helmet-async';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import { default as sites, Site } from '../../constants/sites';
import matchRoute from '../../services/matchRoute';

interface Props extends RouteComponentProps<{}> {
    title?: string;
    image?: string | null;
    description?: string | null;
}

const Meta: SFC<Props> = ({ title, description, location, image }) => {
    // we're going to do the route matching ourselves, because there's a possibility we're not inside a route.
    // this ensures that we always have a fallback title, in case a route for some reason does not include Meta itself.
    const matchedRoute = matchRoute<ExchangeParams>(location.pathname);

    const site: Site | undefined = sites.find(current => !!matchedRoute && current.handle === matchedRoute.match.params.exchange);
    title = `${title ? `${title} | ` : ''} AMS-IX ${site ? site.title : ''}`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="twitter:title" content={title} />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@AMS_IX" />
            <meta name="author" content="Oberon Amsterdam, www.oberon.nl" />
            
            {/* I'd use Fragment here but it makes helmet bork... */}
            {description && <meta name="description" content={description} />}
            {description && <meta property="og:description" content={description} />}
            {description && <meta name="twitter:description" content={description} />}
            
            {image && <meta name="twitter:image:src" content={image} />}
            {image && <meta name="og:image" content={image} />}
        </Helmet>
    );
};

export default withRouter(Meta);