import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { ImageContentBlockFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import Container from '../atoms/Container';
import ContentBody from '../atoms/ContentBody';
import ContentTitle from '../atoms/ContentTitle';
import LinkIconRight from '../atoms/icons/LinkIconRight';
import LazyImg from '../atoms/LazyImg';

export const fragment = gql`
    fragment ImageContentBlock on contentBlocks_image_BlockType {
        image {
            urlSmall: url(transform: "contentBlockImageSmall")
            widthSmall: width(transform: "contentBlockImageSmall")
            urlMedium: url(transform: "contentBlockImageMedium")
            widthMedium: width(transform: "contentBlockImageMedium")
            urlLarge: url(transform: "contentBlockImageLarge")
            widthLarge: width(transform: "contentBlockImageLarge")
            title
        }
        heading
        text
        buttonText
        buttonLink {
            uri
        }
        textAlignment
    }
`;

interface Props {
    contentBlock: ImageContentBlockFragment;
    narrow?: boolean;
}

const ImageContentBlock: SFC<Props> = ({
    contentBlock: { image, heading, text, buttonText, buttonLink, textAlignment },
}) => {
    const blockImage = image && image[0];
    const linkInButton = buttonLink && buttonLink[0];
    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'12rem'}>
            <Wrapper>
                {blockImage && blockImage.urlSmall && (
                    <ImgWrapper>
                        <Image
                            srcSet={`
                                ${blockImage.urlSmall} ${blockImage.widthSmall}w,
                                ${blockImage.urlMedium} ${blockImage.widthMedium}w,
                                ${blockImage.urlLarge} ${blockImage.widthLarge}w`}
                            src={blockImage.urlSmall}
                            alt={blockImage.title || ''}
                            sizes={'100vw'}
                        />
                    </ImgWrapper>
                )}
                {(heading || text || buttonText) && (
                    <Content alignRight={textAlignment === 'right'}>
                        {heading && (
                            <ContentTitle color={'#FFF'} maxWidth={'58rem'} alignRight={textAlignment === 'right'}>
                                {heading}
                            </ContentTitle>
                        )}
                        {text && (
                            <ContentBody color={'#FFF'} maxWidth={'30rem'}>
                                <p>{text}</p>
                            </ContentBody>
                        )}
                        {buttonText && linkInButton && (
                            <ButtonToBottom>
                                <DefaultButton colorScheme={'light'} to={`/${linkInButton.uri || ''}`}>
                                    <span>{buttonText}</span>
                                    <LinkIconRight />
                                </DefaultButton>
                            </ButtonToBottom>
                        )}
                    </Content>
                )}
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div<{ narrow?: boolean }>`
    position: relative;

    ${props =>
        props.narrow
            ? `
        max-width: 900px;
        margin: 0 auto;
        
        @media screen and (min-width: ${props.theme.mediaQueries.l}) {
            > div, > img {
                max-width: 75%;
            }
        }
    `
            : ''};
`;

const Image = styled(LazyImg)`
    max-width: 100%;
    display: block;
`;

const ImgWrapper = styled.div`
    position: relative;
`;

const Content = styled.div<{ alignRight?: boolean }>`
    background: ${props => props.theme.colors.black};
    padding: 2rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding: 4rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 5rem 6rem;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        ${props =>
            props.alignRight
                ? `
            text-align: right;
            align-items: flex-end;
            background: linear-gradient(-90deg, rgba(0,0,0,0.3), rgba(0,0,0,0));
        `
                : `
            background: linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0));
        `}
    }
`;

const ButtonToBottom = styled.div`
    display: flex;
    align-items: flex-end;
    flex: 1;
`;

export default ImageContentBlock;
