import gql from 'graphql-tag';
import { ExchangeParams } from './Exchange';
import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import { DataProps, graphql } from 'react-apollo';
import {
    InfoFragment, InfoQuery as Response
} from '../entities/operationResults';
import { branch, compose, renderComponent } from 'recompose';
import NotFound from './NotFound';
import ContentBlocks, { contentBlockFragments } from './organisms/ContentBlocks';
import Meta from './atoms/Meta';
import { default as RelatedDetail, relatedFragments } from './organisms/RelatedDetail';
import withSpinner from '../hocs/withSpinner';

const GET_INFO_QUERY = gql`
    query Info ($exchange: [String!], $slug: [String!]) {
        entry(section: "info", site: $exchange, slug: $slug) {
            ...Info
        }
    }

    fragment Info on info_info_Entry {
        title
        description
        contentBlocks {
            ...ContentBlock
        }
        relatedItems {
            ...Related
        }
    }
   

    ${relatedFragments}
    ${contentBlockFragments}
`;

export interface InfoParams extends ExchangeParams {
    slug: string;
}

type Props = RouteComponentProps<InfoParams> & DataProps<Response>;

const Info: SFC<Props> = ({ data: { entry } }) => {
    const info: InfoFragment | null = getEntryOfType('info_info_Entry', entry);
    if (!info) { return null; }
    const relatedItems = getRelatedItems(info.relatedItems);

    return (
        <Fragment>
            <Meta title={info.title || ''} description={info.description} />
            <ContentBlocks contentBlocks={info.contentBlocks} />
            {info.relatedItems && info.relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
        </Fragment>
    );
};

const enhance = compose(
    graphql(GET_INFO_QUERY, {
        options: ({ match: { params: { slug, exchange } } }: Props) => ({
            variables: {
                slug,
                exchange
            }
        })
    }),
    withSpinner,
    branch(
        ({ data }: Props) => !data.entry,
        renderComponent(NotFound)
    )
);

export default enhance(Info);