import gql from 'graphql-tag';
import { ExchangeParams } from './Exchange';
import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import { DataProps, graphql } from 'react-apollo';
import {
    EventQuery as Response, EventFragment
} from '../entities/operationResults';
import { branch, compose, renderComponent } from 'recompose';
import NotFound from './NotFound';
import { default as ContentBlocks, contentBlockFragments } from './organisms/ContentBlocks';
import { ThemeProps } from '../styled-components';
import RelatedDetail, { relatedFragments } from './organisms/RelatedDetail';
import DetailWrapper from './atoms/DetailWrapper';
import withSpinner from '../hocs/withSpinner';
import Meta from './atoms/Meta';
import HeroDetail from './molecules/HeroDetail';
import { format } from 'date-fns';

const formatDate = (dateString: string, formatString = "DD MMMM YYYY", timezone = "Europe/Amsterdam") => {
    const date = new Date(dateString);
    // You can use a library like 'date-fns-tz' for timezone handling if needed
    return format(date, formatString);
};

const GET_EVENT_QUERY = gql`
    query Event ($exchange: [String!], $slug: [String!]) {
        entries(section: "event", site: $exchange, slug: $slug) {
            ...Event
        }
    }

    fragment Event on event_event_Entry {
        ...SingleEvent
        relatedItems {
            ...Related
        }
    }
    
    fragment SingleEvent on event_event_Entry {
        title
        description
        location
        startDate
        endDate
        postDate
        heroImage {
            srcSmall: url(transform: "heroSmall")
            widthSmall: width(transform: "heroSmall")
            srcMedium: url(transform: "heroMedium")
            widthMedium: width(transform: "heroMedium")
            srcLarge: url(transform: "heroLarge")
            widthLarge: width(transform: "heroLarge")
            title
        }
        contentBlocks {
            ...ContentBlock
        }
    }

    
    ${relatedFragments}
    ${contentBlockFragments}
`;

interface StoryParams extends ExchangeParams {
    slug: string;
}

type Props = RouteComponentProps<StoryParams> & DataProps<Response> & ThemeProps;

const Event: SFC<Props> = ({ data: { entries } }) => {
    const event: EventFragment | null = getEntryOfType('event_event_Entry', entries && entries[0]);
    if (!event) { return null; }
    const heroImage = event.heroImage && event.heroImage[0];
    const relatedItems = getRelatedItems(event.relatedItems);

    return (
        <Fragment>
            <Meta title={event.title || ''} description={event.description} image={`${heroImage && heroImage.srcSmall}`} />
            <HeroDetail
                img={heroImage ? {
                    title: heroImage.title,
                    srcSmall: heroImage.srcSmall,
                    widthSmall: heroImage.widthSmall !== null ? String(heroImage.widthSmall) : null,
                    srcMedium: heroImage.srcMedium,
                    widthMedium: heroImage.widthMedium !== null ? String(heroImage.widthMedium) : null,
                    srcLarge: heroImage.srcLarge,
                    widthLarge: heroImage.widthLarge !== null ? String(heroImage.widthLarge) : null
                } : undefined}
                title={event.title || ''}
                location={event.location}
                startDate={formatDate(event.startDate || '', "DD MMMM", "Europe/Amsterdam")}
                endDate={formatDate(event.endDate || '', "DD MMMM", "Europe/Amsterdam")}
            />
            <DetailWrapper>
                <ContentBlocks contentBlocks={event.contentBlocks} />
            </DetailWrapper>
            {event.relatedItems && event.relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
        </Fragment>
    );
};

const enhance = compose(
    graphql(GET_EVENT_QUERY, {
        options: ({ match: { params: { slug, exchange } } }: Props) => ({
            variables: {
                slug,
                exchange
            }
        })
    }),
    withSpinner,
    branch(
        ({ data }: Props) => !data.entries,
        renderComponent(NotFound)
    ),
);

export default enhance(Event);
