import { Form, FormikProps, withFormik } from 'formik';
import { FormikBag } from 'formik/dist/withFormik';
import gql from 'graphql-tag';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { path } from 'ramda';
import React, { Fragment, SFC } from 'react';
import { DataProps, graphql } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { branch, compose, lifecycle } from 'recompose';
import {
    DocumentationCollectionItemFragment,
    EventCollectionItemFragment,
    InfoCollectionItemFragment,
    NewsCollectionItemFragment,
    SearchPageFragment,
    SearchQuery as Response,
    SearchQueryVariables,
    ServiceCollectionItemFragment,
    StoryCollectionItemFragment,
} from '../entities/operationResults';
import withSpinner from '../hocs/withSpinner';
import { getEntryOfType } from '../services/entryTypes';
import styled, { ThemeProps, withTheme } from '../styled-components';
import Container from './atoms/Container';
import ContentBody from './atoms/ContentBody';
import { Field } from './atoms/Form';
import IntroTitle from './atoms/IntroTitle';
import Meta from './atoms/Meta';
import { Spinner } from './atoms/Spinner';
import { ExchangeParams } from './Exchange';
import CollectionIntro from './organisms/collections/CollectionIntro';
import { fragments as CollectionFragments } from './organisms/collections/CollectionItem';
import CollectionList from './organisms/collections/CollectionList';
import CollectionPagination from './organisms/collections/CollectionPagination';

const GET_COLLECTIONS_QUERY = gql`
    query Search($site: [String!], $offset: Int, $itemLimit: Int, $query: String) {
        items: entries(
        site: $site
        section: ["event", "news", "story", "info", "documentation", "service"]
        offset: $offset
        limit: $itemLimit
        search: $query
        orderBy: "score"
    ) {
        ... on event_event_Entry {
            ...EventCollectionItem
        }
        ... on news_news_Entry {
            ...NewsCollectionItem
        }
        ... on story_story_Entry {
            ...StoryCollectionItem
        }
        ... on info_info_Entry {
            ...InfoCollectionItem
        }
        ... on documentation_documentation_Entry {
            ...DocumentationCollectionItem
        }
        ... on service_service_Entry {
            ...ServiceCollectionItem
        }
        title
    }
    page: entry(site: $site, section: "search") {
        ...SearchPage
    }
    }
    fragment SearchPage on search_search_Entry {
        introHeading
        introText
    }
    ${CollectionFragments.event}
    ${CollectionFragments.news}
    ${CollectionFragments.story}
    ${CollectionFragments.info}
    ${CollectionFragments.documentation}
    ${CollectionFragments.service}
`;

interface Values {
    query: string;
}

type Props = DataProps<Response, SearchQueryVariables> &
    ThemeProps &
    RouteComponentProps<ExchangeParams> &
    FormikProps<Values>;

const handleSubmit = debounce((values: Values, { props }: FormikBag<Props, Values>) => {
    const parsed = queryString.parse(props.location.search);
    parsed.query = values.query;

    props.history.replace({
        ...props.location,
        search: queryString.stringify(parsed),
    });
}, 500);

const enhance = compose(
    withRouter,
    withTheme,
    withFormik<Props, Values>({
        handleSubmit,
        mapPropsToValues: props => ({
            query: queryString.parse(props.location.search).query || '',
        }),
    }),
    lifecycle<Props, {}>({
        componentDidUpdate(prev: Props) {
            if (prev.values.query !== this.props.values.query) {
                this.props.submitForm();
            }
        },
    }),
    graphql(GET_COLLECTIONS_QUERY, {
        options: ({ match, location: { search } }: Props) => {
            const limit = 8;
            let offset = 0;
            const parsed = queryString.parse(search);
            const currentPage = parseInt(parsed.page, 10) || 1;
            const query = parsed.query || '';

            if (currentPage !== 1) {
                offset = (currentPage - 1) * limit;
            }

            return {
                variables: {
                    site: match.params.exchange,
                    offset,
                    itemLimit: limit,
                    query,
                },
            };
        },
    }),
    branch(({ data }: Props) => !data.items, withSpinner)
);

const FloatingSpinner = styled(Spinner)`
    position: absolute;
    right: 2.6rem;
    top: 1.6rem;
    transition: opacity 0.15s;
`;

const Search: SFC<Props> = ({ data, theme }) => {
    const items: Array<
        | NewsCollectionItemFragment
        | EventCollectionItemFragment
        | StoryCollectionItemFragment
        | InfoCollectionItemFragment
        | DocumentationCollectionItemFragment
        | ServiceCollectionItemFragment
    > = [];
    if (data.items && data.items.entries) {
        const entriesArray = [...data.items.entries()];
        for (const item of entriesArray) {
            if (!item || !item[1]) {
                continue;
            }

            // @ts-ignore
            switch (item[1].__typename) {
                case 'news_news_Entry':
                case 'event_event_Entry':
                case 'story_story_Entry':
                case 'info_info_Entry':
                case 'documentation_documentation_Entry':
                case 'service_service_Entry':
                    // @ts-ignore
                    items.push(item[1]);
                    break;
                default:
                    break;
            }
        }
    }

    const page: SearchPageFragment | null = getEntryOfType('search_search_Entry', data.page);

    const totalPages = path<number>(['items', 'pageInfo', 'totalPages'], data || {});
    const title = path<string>(['introHeading'], page || {});
    const intro = path<string>(['introText'], page || {});

    return (
        <Fragment>
            <Meta title={title} />
            <CollectionIntro>
                <div style={{ width: '100%' }}>
                    <IntroTitle color={theme.colors.white}>{title}</IntroTitle>
                    {intro && <ContentBody color={theme.colors.white} dangerouslySetInnerHTML={{ __html: intro }} />}
                    <Form>
                        <Field name="query" label="" placeholder="Enter a search query..." />
                    </Form>
                </div>
            </CollectionIntro>
            <Container
                maxWidth
                paddingTop={'5.6rem'}
                paddingTopL={'5.6rem'}
                paddingBottom={'4rem'}
                paddingBottomL={'4rem'}
                backgroundColor={theme.colors.white}
            >
                <FloatingSpinner tiny style={{ opacity: data.loading ? 1 : 0 }} />
                {totalPages === 0 && (
                    <ContentBody>
                        <p>No search results, try adjusting your search query.</p>
                    </ContentBody>
                )}

                <CollectionList collectionItems={items} />
                {!!totalPages && totalPages > 1 && <CollectionPagination totalPages={totalPages} />}
            </Container>
        </Fragment>
    );
};

export default enhance(Search);
