import gql from 'graphql-tag';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import { AudioContentBlockFragment } from '../../entities/operationResults';
import Container from '../atoms/Container';

export const fragment = gql`
    fragment AudioContentBlock on contentBlocks_audio_BlockType {
        podcastUrl
        podcastDescription
    }
`;

interface Props {
    contentBlock: AudioContentBlockFragment;
}

const AudioContentBlock: SFC<Props> = ({ contentBlock: {podcastUrl, podcastDescription} }) => (
    <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'8rem'}>
        <Wrapper>
            <IFrame src={podcastUrl ? podcastUrl : ''} title={podcastDescription ? podcastDescription : ''}
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen><a href={podcastUrl ? podcastUrl : ''}>{podcastDescription}</a></IFrame>
        </Wrapper>
    </Container>
);

export default AudioContentBlock;

const Wrapper = styled.div`
    text-align: center;
    position: relative;
`;

const IFrame = styled.iframe`
    max-width: 900px;
    width: 100%;
    margin: auto;
`;
