import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { ApplicationFormContentBlockFragment } from '../../entities/operationResults';
import ContactSlideover from '../organisms/ContactSlideover';
import RegisterSlide from './RegisterSlide';

export const fragment = gql`
    fragment ApplicationFormContentBlock on contentBlocks_applicationForm_BlockType {
        id
        buttonText
        applicationType
    }
`;

interface Props {
    contentBlock: ApplicationFormContentBlockFragment;
}

const ApplicationFormContentBlock: SFC<Props> = ({ contentBlock: { id, buttonText, applicationType } }) => {
    return (
        <RegisterSlide
            slideover={ContactSlideover}
            buttonText={buttonText || 'Application form'}
            resellerForm={applicationType === 'reseller'}
        />
    );
};

export default ApplicationFormContentBlock;