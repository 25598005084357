import React from 'react';
import Helmet from 'react-helmet-async';
import styled from '../../styled-components';

type Props = React.HTMLAttributes<HTMLDivElement>;

const DetailWrapper = styled.section<Props>`
    background-color: white;
    margin: 0 auto;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        max-width: 636px;
        margin: 0 auto;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        max-width: 960px;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 1152px;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        max-width: 1344px;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xxl}) {
        max-width: 1440px;
    }

    &::after {
        clear: both;
        content: '';
        display: table;
    }
`;

const helmet = <Helmet><body className={'grey'} /></Helmet>

export default ({ children }: Props) => (
    <DetailWrapper>
        { helmet }
        <div>{children}</div>
    </DetailWrapper>
);
