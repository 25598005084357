import withModal, { ModalInProps } from '../../hocs/withModal';
import React, { SFC } from 'react';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import Container from '../atoms/Container';
import * as Intro from '../molecules/Intro';
import styled from '../../styled-components';
import ContactForm from '../molecules/ContactForm';
import { DataProps, graphql } from 'react-apollo';
import { ExchangeParams } from '../Exchange';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    ConnectedNetworkConnectionFragment,
    ConnectedNetworkDetailsFragment,
    ConnectedNetworkQuery
} from '../../entities/operationResults';
import { getEntriesOfType, getEntryOfType } from '../../services/entryTypes';

const GET_NETWORK_QUERY = gql`
    query ConnectedNetwork ($exchange: [String!], $id: [QueryArgument]) {
        connectedNetwork: entry(site: $exchange, id: $id, section: "network") {
            ...ConnectedNetworkDetails
        }
    }
    
    fragment ConnectedNetworkDetails on network_network_Entry {
        connections {
            ...ConnectedNetworkConnection
        }
    }
    
    fragment ConnectedNetworkConnection on networkConnection_networkConnection_Entry {
        IPv4: ipv4
        IPv6: ipv6
        Speed: speed
        Location: colocation
        VLAN: vlan
    }
`;

interface InProps extends ModalInProps {
    networkName: string;
    networkId: number;
}

interface Outprops extends RouteComponentProps<ExchangeParams>, DataProps<ConnectedNetworkQuery> {
}

const ConnectedNetworksSlideover: SFC<InProps & Outprops> = ({ networkId, networkName, data }) => {
    const connectedNetwork: ConnectedNetworkDetailsFragment | null = getEntryOfType('network_network_Entry', data.connectedNetwork);
    const connections: ConnectedNetworkConnectionFragment[] | null = connectedNetwork && getEntriesOfType('networkConnection_networkConnection_Entry', connectedNetwork.connections);
    return (
        <Wrapper maxWidth>
            <FirstColumn>
                <Intro.Container>
                    <h1>
                        Contact
                        <NetworkName>{ networkName }</NetworkName>
                    </h1>
                    <Intro.Sep />
                    <Intro.Text>
                        <p>
                            You can contact them directly by filling in the form below. <br/><br/>
                            <b>Important:</b> this contact form is meant for peering-related requests only. Any other type of request can be considered as spam.
                        </p>
                    </Intro.Text>
                </Intro.Container>

                <ContactForm toNetwork={networkId} isDark />
            </FirstColumn>
            {connections && (
                <NetworkInfo>
                    {connections.map((connection: ConnectedNetworkConnectionFragment, index: number) => (
                        <NetworkConnection key={index}>
                            {Object.keys(connection).map((key: keyof ConnectedNetworkConnectionFragment) => {
                                return key !== '__typename' && connection[key] ? (
                                    <NetworkDetail key={key}>
                                        <h3>{key}</h3>
                                        <p>{connection[key]}</p>
                                    </NetworkDetail>
                                ) : null;
                            })}
                        </NetworkConnection>
                    ))}
                </NetworkInfo>
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    > div {
        display: flex;
        flex-direction: column;
        
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        > div {
            flex-direction: row;
        }
    }
`;

const FirstColumn = styled.div`
    flex: 1;
`;

const NetworkName = styled.span`
    display: block;
    color: ${props => props.theme.colors.tango};
`;

const NetworkInfo = styled.div`
    margin: 3rem 0;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        max-width: 320px;
        margin-left: 15rem;
        margin-top: 6rem;
    }

    h3 {
        color: ${props => props.theme.colors.baliHai};
        font: ${props => props.theme.fonts.large.storyTitle};
        text-transform: uppercase;
        font-weight: 600;
        margin: 0;
    }
    
    p {
        margin: 0;
        font: 1.6rem/2.6rem chalet-london-sixty, sans-serif;
    }
`;

const NetworkDetail = styled.div`
    margin-bottom: 1rem;
`;

const NetworkConnection = styled.div`
    &:not(:first-child) {
        margin-top: 1.5rem;
    }
    &:not(:first-child):before {
        content: '';
        display: block;
        margin-bottom: 1.5rem;
        width: 6rem;
        height: 0.15rem;
        background-color: ${props => props.theme.colors.tango};
    }
`;

const enhanced = compose<Outprops, InProps>(
    withModal,
    withRouter,
    graphql(GET_NETWORK_QUERY, {
        options: ((props: InProps & Outprops) => ({
            variables: {
                exchange: props.match.params.exchange,
                id: [props.networkId]
            }
        }))
    })
);

export default enhanced(ConnectedNetworksSlideover);
