import React, { Fragment, SFC } from 'react';
import styled, { ThemeProps, withTheme } from '../styled-components';
import Meta from './atoms/Meta';
import gql from 'graphql-tag';
import { compose, withState } from 'recompose';
import { DataProps, graphql } from 'react-apollo';
import { RouteConfigComponentProps } from 'react-router-config';
import { ExchangeParams } from './Exchange';
import {
    ConnectedNetworksQuery, ConnectedNetworkInfoFragment, ConnectedNetworkkRowFragment, ConnectedNetworksStatsFragment
} from '../entities/operationResults';
import { getEntriesOfType, getEntryOfType } from '../services/entryTypes';
import withWidth, { WidthProps } from '../hocs/withWidth';
import CollectionTable from './molecules/CollectionTable';
import ConnectedNetworksSlideover from './organisms/ConnectedNetworksSlideover';
import WishlistSlideover from './organisms/WishlistSlideover';
import CollectionIntro from './organisms/collections/CollectionIntro';
import ContentBody from './atoms/ContentBody';
import IntroTitle from './atoms/IntroTitle';
import { path } from 'ramda';
import ConnectedNetworksStats from './molecules/ConnectedNetworksStats';
import CollectionFilters from './molecules/CollectionFilters';
import * as queryString from 'querystring';
import generatePath from 'react-router-dom/generatePath';
import { getStartedRoute } from '../constants/routes';
import withSpinner from '../hocs/withSpinner';
import SpinnerPage from './molecules/SpinnerPage';

const GET_CONNECTED_NETWORK_QUERY = gql`
    query ConnectedNetworks ($exchange: [String!], $search: String) {
        connectedNetworksPage: entry(section: "connectedNetworkCollection", site: $exchange) {
            ...ConnectedNetworkInfo
        }

        connectedNetworksTable: entries(section: "network", site: $exchange, search: $search, orderBy: "title ASC, asNumber ASC") {
            ...ConnectedNetworkkRow
        }

        connectedNetworksStats: globalSet(site: $exchange, handle: "globalStats") {
            ...on globalStats_GlobalSet {
                ...ConnectedNetworksStats
            }
        }
    }
    
    fragment ConnectedNetworksStats on globalStats_GlobalSet {
        routeserverPeers
        connectedNetworks
        customerPorts
        ipv6Peers
    }
    
    fragment ConnectedNetworkInfo on connectedNetworkCollection_connectedNetworkCollection_Entry {
        title
        introHeading
        introText
        exportLinks {
            title
            url
        }
    }
    
    fragment ConnectedNetworkkRow on network_network_Entry {
        id
        title
        memberUrl
        asNumber
        peeringPolicy
        routeserver
        vlan
        colocation
    }
`;

type Props = RouteConfigComponentProps<ExchangeParams> & DataProps<ConnectedNetworksQuery> & WidthProps & ThemeProps & {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
    isModalWishlistOpen: boolean;
    setIsModalWishlistOpen: (isModalWishlistOpen: boolean) => void;
    setOpenNetworkName: (name: string) => void;
    openNetworkName: string;
    setOpenNetworkId: (id: number) => void;
    openNetworkId: string;
};

const columnWidths = {
    organisation: {
        l: '18%', m: '25%', s: '65%'
    },
    url: {
        l: '17%', m: '25%', s: '0'
    },
    as: {
        l: '8%', m: '19%', s: '20%'
    },
    policy: {
        l: '12%', m: '19%', s: '0'
    },
    routeServer: {
        l: '9%', m: '0', s: '0'
    },
    options: {
        l: '12%', m: '12%', s: '15%'
    },
    vlan: {
        l: '6%', m: '0', s: '0'
    },
    colocation: {
        l: '18%', m: '0', s: '0'
    }
};

const ConnectedNetworks: SFC<Props> = ({ data, setWidth, width, theme, isModalOpen, setIsModalOpen, isModalWishlistOpen, setIsModalWishlistOpen, openNetworkName, setOpenNetworkName, openNetworkId, setOpenNetworkId, match }) => {
    const connectedNetworksPage: ConnectedNetworkInfoFragment | null = getEntryOfType('connectedNetworkCollection_connectedNetworkCollection_Entry', data.connectedNetworksPage);
    const connectedNetworksTable: ConnectedNetworkkRowFragment[] | null = getEntriesOfType('network_network_Entry', data.connectedNetworksTable);
    const connectedNetworksStats = path<ConnectedNetworksStatsFragment>(['connectedNetworksStats'], data);
    const exportLinks: ConnectedNetworkInfoFragment['exportLinks'] = connectedNetworksPage && connectedNetworksPage.exportLinks; // && path<ConnectedNetworkInfoFragment['exportLinks']>('exportLinks', exp) | null = connectedNetworksPage && getEntriesOfType('ExportLinksTable', connectedNetworksPage.exportLinks);

    const openWishlistSlideover = () => {
        setIsModalWishlistOpen(true);
    }

    const rowRenderer = (index: number) => {
        const asNumber = connectedNetworksTable[index].asNumber;

        return [
            {
                text: connectedNetworksTable[index].title || '',
                width: columnWidths.organisation,
            },
            {
                text: connectedNetworksTable[index].memberUrl || '',
                width: columnWidths.url,
                url: connectedNetworksTable[index].memberUrl || ''
            },
            {
                text: asNumber ? asNumber.toString() : '',
                width: columnWidths.as
            },
            {
                text: connectedNetworksTable[index].peeringPolicy || '',
                width: columnWidths.policy
            },
            {
                text: connectedNetworksTable[index].routeserver ? 'Yes' : 'No',
                width: columnWidths.routeServer
            },
            {
                text: connectedNetworksTable[index].colocation || '',
                width: columnWidths.colocation
            },
            {
                text: connectedNetworksTable[index].vlan || '',
                width: columnWidths.vlan
            },
            {
                text: 'More info',
                width: columnWidths.options,
                cta: true,
                iconType: 'popup',
                onClick: () => {
                    setOpenNetworkId(Number(connectedNetworksTable[index].id));
                    setOpenNetworkName(connectedNetworksTable[index].title || '');
                    setIsModalOpen(true);
                },
                minWidth: '14rem'
            }

        ];
    };

    const headerItems = [
        {
            text: 'Organisation',
            width: columnWidths.organisation
        },
        {
            text: 'URL',
            width: columnWidths.url
        },
        {
            text: 'AS',
            width: columnWidths.as
        },
        {
            text: 'Policy',
            width: columnWidths.policy
        },
        {
            text: 'Routeserver',
            width: columnWidths.routeServer
        },
        {
            text: 'Location',
            width: columnWidths.colocation
        },
        {
            text: 'VLAN',
            width: columnWidths.vlan
        },
        {
            text: 'Options',
            width: columnWidths.options,
            minWidth: '14rem'
        }
    ];

    if (!connectedNetworksPage) { return (<SpinnerPage />); }

    return (
        <Fragment>
            <Meta title={connectedNetworksPage.introHeading || connectedNetworksPage.title || ''} />
            <CollectionIntro>
                <div>
                    <IntroTitle
                        color={theme.colors.white}
                    >
                        {connectedNetworksPage.introHeading}
                    </IntroTitle>
                    {connectedNetworksPage.introText && (
                        <ContentBody color={theme.colors.white} dangerouslySetInnerHTML={{ __html: connectedNetworksPage.introText }}/>
                    )}
                </div>
                {connectedNetworksStats && (
                    <Column>
                        <ConnectedNetworksStats stats={connectedNetworksStats} />
                    </Column>
                )}
            </CollectionIntro>
            <CollectionFilters
                search
                exportLinks={exportLinks}
                passedFunction={openWishlistSlideover}
                wishlist
            />
            <CollectionTable
                numOfRows={connectedNetworksTable.length}
                getRowData={rowRenderer}
                headerItems={headerItems}
            />
            <ConnectedNetworksSlideover
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                backgroundColor={theme.colors.mirage}
                textColor={theme.colors.white}
                networkId={+openNetworkId}
                networkName={openNetworkName}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
            />
            <WishlistSlideover
                isOpen={isModalWishlistOpen}
                onClose={() => setIsModalWishlistOpen(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
            />
        </Fragment>
    );
};

const Column = styled.div`
    display: flex; 
    flex-direction: column;
`;

const enhance = compose(
    withWidth,
    withTheme,
    withState('isModalOpen', 'setIsModalOpen', false),
    withState('isModalWishlistOpen', 'setIsModalWishlistOpen', false),
    withState('openNetworkName', 'setOpenNetworkName', ''),
    withState('openNetworkId', 'setOpenNetworkId', 0),
    graphql(GET_CONNECTED_NETWORK_QUERY, {
        options: ({ match: { params: { exchange } }, location }: Props) => {
            const query = queryString.parse(location.search.replace('?', ''));

            if (typeof query.search === 'string') {
                // Some trickery to allow searching by IP
                if (query.search.match(/^[\d.]+$/)) {
                    query.search = query.search.replace(/\./g, '_');
                } else {
                    query.search = query.search.replace(/:/g, '_');
                }

                query.search = query.search.replace(/[^\w]/g, ' ');
            }

            const graphQLVariables = {
                variables: {
                    exchange,
                    ...query
                }
            };

            return graphQLVariables;
        }
    }),
    withSpinner
);

export default enhance(ConnectedNetworks);
