import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { TableContentBlockFragment } from '../../entities/operationResults';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import ContentBody from '../atoms/ContentBody';
import ContentTitle from '../atoms/ContentTitle';

export const fragment = gql`
    fragment TableContentBlock on contentBlocks_table_BlockType {
        heading
        table
    }
`;

interface Props {
    contentBlock: TableContentBlockFragment;
}

const TextContentBlock: SFC<Props> = ({ contentBlock: { heading, table } }) => {
    if (!heading && !table) {
        return null;
    }
    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'8rem'}>
            {heading && <Heading>{heading}</Heading>}
            {table && <Table dangerouslySetInnerHTML={{ __html: table }} />}
        </Container>
    );
};

const Heading = styled(ContentTitle)`
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto 3.2rem auto;
    }
`;

const Table = styled(ContentBody)`
    overflow-y: auto;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
    }
`;

export default TextContentBlock;
