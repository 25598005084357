import React, { SFC } from 'react';
import Helmet from 'react-helmet-async';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import generatePath from 'react-router-dom/generatePath';
import { compose, withState } from 'recompose';
import { exchangeRoute } from '../../constants/routes';
import sites from '../../constants/sites';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import LinkIconRight from '../atoms/icons/LinkIconRight';
import { ExchangeParams } from '../Exchange';
import poweredBySites from '../../constants/poweredBySites';
import { NavHeading } from '../atoms/NavItems';

const enhanced = compose<OutProps, InProps>(
    withTheme,
    withRouter,
    withState('activeIndex', 'setActiveIndex', null)
);

interface OutProps extends ThemeProps, RouteComponentProps<ExchangeParams> {
    activeIndex: number | null;
    setActiveIndex: (activeIndex: number | null) => void;
}

interface InProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    loginUrl?: string;
}

const MobileExchangeMenu: SFC<OutProps & InProps> = ({ visible, theme, activeIndex, setActiveIndex, setVisible }) => {
    const siteList = sites;
    const poweredBySiteList = poweredBySites;
    const helmet = <Helmet><body className={'lock'} /></Helmet>;

    return (
        <React.Fragment>
            <MobileMenuContainer visible={visible}>
            <NavHeading>AMS-IX locations</NavHeading>
                <MobileMenuList>
                    {siteList.map((menuItem, index) => {
                        return (
                            <NavItem key={menuItem.handle}>
                                <NavLink
                                    onClick={() => {
                                        setVisible(false);
                                    }}
                                    to={generatePath(exchangeRoute.path, { exchange: menuItem.handle })}
                                >
                                    {menuItem.title}
                                    <LinkIconRight />
                                </NavLink>
                            </NavItem>
                        );
                    })}
                     <NavHeading>Powered by AMS-IX</NavHeading>
                     {poweredBySiteList.map(
                            (site, index) =>
                            <NavItem key={index}>
                                <ExternalNavlink 
                                    target="_blank" 
                                    href={site.url}>
                                        {site.title} 
                                        <LinkIconRight/>
                                </ExternalNavlink>
                            </NavItem> 
                        )}
                </MobileMenuList>
            </MobileMenuContainer>

            {visible && (
                helmet
            )}
        </React.Fragment>
    );
};

const MobileMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 1.6rem 0;
`;

const NavItem = styled.li`
    padding: 0;
    font-family: chalet-new-york-sixty;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: -0.028rem;
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.mystic};
    &:last-of-type {
        border-bottom: none;
    }
    a {
        padding: 2rem 0;
        color: inherit;
        text-decoration: none;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        svg {
            text-align: right;
        }
    }
`;

const ExternalNavlink = styled.a`
    color: ${(props) => props.theme.colors.black};
    font: 1.6rem/2.4rem chalet-new-york-sixty,sans-serif;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 2.4rem 0;
    &:hover{
        color: ${(props) => props.theme.colors.tango};
    }
`;

const NavLink = styled(Link)`
    padding: 2rem 0;
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    svg {
        text-align: right;
    }
`;

const MobileMenuContainer = styled.div<{ visible: boolean }>`
    position: absolute;
    overflow: auto;
    border-top: 1px solid ${props => props.theme.colors.mystic}
    top: 100%;
    left: 0;
    background: white;
    width: calc(100% - 12.8rem);
    height: calc(100vh - 10.1rem);
    padding: 1.2rem 3.2rem 2.4rem 3.2rem;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transform: ${props => (props.visible ? 'translateX(0%)' : 'translateX(-100%)')};
    transition: all 0.3s ease-in-out;
    button{
        padding: 2rem 0;
    }
`;

export default enhanced(MobileExchangeMenu);
