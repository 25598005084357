import React, { SFC } from 'react';
import gql from 'graphql-tag';
import { LogosContentBlockFragment } from '../../entities/operationResults';
import { Img } from 'react-image-loading';
import Container from '../atoms/Container';
import styled from 'styled-components';

export const fragment = gql`
    fragment LogosContentBlock on contentBlocks_logos_BlockType {
        logos {
            src: url(transform: "contentBlockLogo")
            src2x: url(transform: "contentBlockLogo2x")
            title
            id
        }
    }
`;

interface Props {
    contentBlock: LogosContentBlockFragment;
}

const LogosContentBlock: SFC<Props> = ({ contentBlock: { logos } }) => logos && (
    <Container maxWidth>
        <Logos>
            {logos.map(logo => logo && (
                <ImgWrapper key={logo.id!}>
                    <Img alt={logo.title || undefined} src={logo.src!} srcSet={`${logo.src}, ${logo.src2x} 2x`} />
                </ImgWrapper>
            ))}
        </Logos>
    </Container>
);

const ImgWrapper = styled.div`
    min-height: 100%;
    position: relative;
    overflow: hidden;
    display:inline-block;
    margin-bottom: 2rem;
    text-align: center;
    
    img {
        object-position: center;
        object-fit: cover;
    }
    
    @media screen and (max-width: ${(props) => props.theme.mediaQueries.s}) {
        width: 50%;
    }
`;

const Logos = styled.div`
    padding-top: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
`;
export default LogosContentBlock;
