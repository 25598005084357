import gql from 'graphql-tag';
import { ExchangeParams } from './Exchange';
import { RouteComponentProps } from 'react-router';
import React, { Fragment, SFC } from 'react';
import { getEntryOfType, getRelatedItems } from '../services/entryTypes';
import { DataProps, graphql } from 'react-apollo';
import {
    StoryFragment,
    StoryQuery as Response
} from '../entities/operationResults';
import { branch, compose, renderComponent } from 'recompose';
import Container from './atoms/Container';
import NotFound from './NotFound';
import { default as ContentBlocks, contentBlockFragments } from './organisms/ContentBlocks';
import Meta from './atoms/Meta';
import HeroDetail from './molecules/HeroDetail';
import AuthorInfo from './molecules/AuthorInfo';
import { ThemeProps } from '../styled-components';
import RelatedDetail, { relatedFragments } from './organisms/RelatedDetail';
import DetailWrapper from './atoms/DetailWrapper';
import withSpinner from '../hocs/withSpinner';

const GET_STORY_QUERY = gql`
    query Story ($exchange: [String!], $slug: [String!]) {
        entry(section: "story", site: $exchange, slug: $slug) {
            ...Story
        }
    }

    fragment Story on story_story_Entry {
        ...SingleStory
        relatedItems {
            ...Related
        }
    }
    
    fragment SingleStory on story_story_Entry {
        title
        description
        postDate
        heroImage {
            srcSmall: url(transform: "heroSmall")
            widthSmall: width(transform: "heroSmall")
            srcMedium: url(transform: "heroMedium")
            widthMedium: width(transform: "heroMedium")
            srcLarge: url(transform: "heroLarge")
            widthLarge: width(transform: "heroLarge")
            title
        }
        author {
            ... on User {
                name
                functionTitle
                photo {
                    url(transform: "newsThumbnail")
                    title
                }
            }
        }
        service {
            title
        }
        contentBlocks {
            ...ContentBlock
        }
    }
    
    ${relatedFragments}
    ${contentBlockFragments}
`;

interface StoryParams extends ExchangeParams {
    slug: string;
}

type Props = RouteComponentProps<StoryParams> & DataProps<Response> & ThemeProps;

const Story: SFC<Props> = ({ data: { entry } }) => {
    const story: StoryFragment | null = getEntryOfType('story_story_Entry', entry);
    if (!story) { return null; }
    const heroImage = story.heroImage && story.heroImage[0];
    const relatedItems = getRelatedItems(story.relatedItems);

    return (
        <Fragment>
            <Meta title={story.title || ''} description={story.description} image={`${heroImage && heroImage.srcSmall}`} />
            <HeroDetail
                img={heroImage ? {
                    title: heroImage.title,
                    srcSmall: heroImage.srcSmall,
                    widthSmall: heroImage.widthSmall ? heroImage.widthSmall.toString() : null,
                    srcMedium: heroImage.srcMedium,
                    widthMedium: heroImage.widthMedium ? heroImage.widthMedium.toString() : null,
                    srcLarge: heroImage.srcLarge,
                    widthLarge: heroImage.widthLarge ? heroImage.widthLarge.toString() : null
                } : undefined}
                title={story.title || ''}
                subTitle="An AMS-IX Story"
            />
            <DetailWrapper>
                <Container maxWidth paddingTop="4rem" paddingTopL="8rem">
                    <AuthorInfo author={story.author} postDate={null}/>
                </Container>
                <ContentBlocks contentBlocks={story.contentBlocks} />
            </DetailWrapper>
            {story.relatedItems && story.relatedItems.length > 0 && <RelatedDetail relatedItems={relatedItems} />}
        </Fragment>
    );
};

const enhance = compose(
    graphql(GET_STORY_QUERY, {
        options: ({ match: { params: { slug, exchange } } }: Props) => ({
            variables: {
                slug,
                exchange
            }
        })
    }),
    withSpinner,
    branch(
        ({ data }: Props) => !data.entry,
        renderComponent(NotFound)
    ),
);

export default enhance(Story);